import React, { createContext, useContext, useEffect } from 'react';

import { 
    injectHtmlBanner, injectStylesSheet, 
    removeInjectedBanner, removeInjectedStyleSheet,
} from '../../utils/injectBanner';
import { useLocalStorage } from '../../api/useLocalStorage';

import * as Utils from './utils';

const EventBannerContext = createContext();

const EventBannerProvider = ({ children }) => {
    const { events = [] } = useLocalStorage();

    useEffect(() => {
        const cleanup = () => {
            events.forEach(event => {
                removeInjectedBanner(Utils.getEventDivName(event.type));
            });
            removeInjectedStyleSheet();
        };

        cleanup();

        if (events.length > 0) {
            injectStylesSheet();
            events.forEach(event => {
                injectHtmlBanner(
                    Utils.getEventDivName(event.type), 
                    Utils.eventTypeMessageTemplate(event), 
                    Utils.EventTypeStyles[event.type],
                );
            });
        }

        return cleanup;
    }, [events]);

    const contextValue = { events };

    return (
        <EventBannerContext.Provider value={contextValue}>
            {children}
        </EventBannerContext.Provider>
    );
};

const useEventBannerContext = () => {
    const context = useContext(EventBannerContext);
  
    if (!context) {
        throw new Error('useEventBannerContext must be used within an EventBannerProvider');
    }
  
    return context;
};

export {
    useEventBannerContext,
    EventBannerProvider,
}
