import { withStyles } from "@material-ui/core/styles";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";


export const buttonStyle = withStyles((theme) => ({
	root: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,

		'&:hover': {
			color: theme.palette.background.paper,
			backgroundColor: theme.palette.primary.light,
		},

		'&.Mui-selected': {
			color: theme.palette.background.paper,
			backgroundColor: theme.palette.primary.main,

			'&:hover': {
				backgroundColor: theme.palette.primary.light,
			},
		}
	},

}));

export const StyledToggleButton = buttonStyle(ToggleButton);


export const StyledNoToggleButton = withStyles((theme) => ({
	root: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,

		'&.Mui-selected': {
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		}
	},
}))(ToggleButton);


export const buttonGroupStyle = withStyles((t) => ({
	root: {
		marginBottom: 4,
	},
}));

export const FilterToggleButtonGroup = buttonGroupStyle(ToggleButtonGroup);
