import { 
    differenceInDays, differenceInHours, 
    differenceInMinutes, differenceInSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { isEmpty } from '../../utils/validation/required';
import { EventsType } from '../../entities/EventsType';

import { DATE_TIME_FORMAT } from '../../components/_helpers/dateTime';
import { formatDateTime, parseDate as formatDate, parseTime as formatTime } from '../../components/inputs';

const EventTypeStyles = {
    [EventsType.COMMON]: {
        backgroundColor: '#D3D3D3',
        color: '#333333',
    },
    [EventsType.MAINTENANCE]: {
        backgroundColor: '#FF8C8C',
        color: '#333333',
    },
};

const now = (_timeZone) => {
    let timeZone = _timeZone;
    if (isEmpty(timeZone)) {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const _now = new Date().toISOString();
    return utcToZonedTime(_now, timeZone);
};

const date = (value, _timeZone) => {
    if (isEmpty(value)) return null;

    let timeZone = _timeZone;
    if (isEmpty(timeZone)) {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    const parsedDate = new Date(`${value}Z`);
    return utcToZonedTime(parsedDate, timeZone);
};

const formatDatetime = (value, _format = DATE_TIME_FORMAT) => formatDateTime(value, true, _format);

const calculdateDatesDiff = (_leftValue, _rightValue, timeZone) => {
    if (isEmpty(_leftValue)) return '';

    let leftValue = date(_leftValue, timeZone);

    let rightValue;
    if (isEmpty(rightValue)) {
        rightValue = now(timeZone);
    } else {
        rightValue = date(_rightValue, timeZone);
    }

    if (leftValue < rightValue) {
        [leftValue, rightValue] = [rightValue, leftValue];
    }

    const days = differenceInDays(leftValue, rightValue);
    const hours = differenceInHours(leftValue, rightValue) % 24;
    const minutes = differenceInMinutes(leftValue, rightValue) % 60;
    const seconds = differenceInSeconds(leftValue, rightValue) % 60;

    return 'Dd HH:mm:ss'
        .replace('Dd', days ? `${days}d` : '')
        .replace('HH', String(hours).padStart(2, '0'))
        .replace('mm', String(minutes).padStart(2, '0'))
        .replace('ss', String(seconds).padStart(2, '0'));
};

const countdown = (leftValue, rightValue, timeZone) => {
    if (isEmpty(leftValue)) return '';

    const countdownElementId = `countdown-${String(leftValue)}-${String(rightValue)}-${String(timeZone)}`;

    const updateCountdown = () => {
        const coundownFormatedText = calculdateDatesDiff(leftValue, rightValue, timeZone);
        const element = document.getElementById(countdownElementId);
        if (element) {
            element.innerText = coundownFormatedText;
        }
    };

    updateCountdown();
    setInterval(updateCountdown, 1000);

    return `<span id="${countdownElementId}"></span>`;
};

const syntaxDocumentation = {
    objects: {
        event: {
            description: "Dictionary: 'event'",
            args: "key: string",
            returns: {
                "type": "number", 
                "id": "number",
                "start_datetime": "string",
                "end_datetime": "string",
                "status": "number",
            },
            ref: null,
        },
    },
    functions: {
        formatDatetime: {
            description: "Function: 'formatDatetime'",
            args: {
                "value": "string, required",
                "format": "string, optional",
            },
            returns: "string",
            ref: formatDatetime,
        },
        formatDate: {
            description: "Function: 'formatDate'",
            args: {
                "value": "string, required",
            },
            returns: "string",
            ref: formatDate,
        },
        formatTime: {
            description: "Function: 'formatTime'",
            args: {
                "value": "string, required",
            },
            returns: "string",
            ref: formatTime,
        },
        date: {
            description: "Function: 'date'",
            args: {
                "value": "string | Date, optional",
                "timeZone": "string, optional",
            },
            returns: "Date",
            ref: date,
        },
        now: {
            description: "Function: 'now'",
            args: {
                "timeZone": "string, optional",
            },
            returns: "Date",
            ref: now,
        },
        calculdateDatesDiff: {
            description: "Function: 'calculdateDatesDiff'",
            args: {
                "leftValue": "string | Date, required",
                "rightValue": "string | Date, optional",
                "timeZone": "string, optional",
            },
            returns: "string",
            ref: calculdateDatesDiff,
        },
        countdown: {
            description: "Function: 'countdown'",
            args: {
                "leftValue": "string | Date, required",
                "rightValue": "string | Date, optional",
                "timeZone": "string, optional",
            },
            returns: "string",
            ref: countdown,
        },
    },
};

const parseEventText = (event, targetField) => {
    let parsedDescription = event[targetField].trim();
    if (isEmpty(parsedDescription)) return '';

    parsedDescription = parsedDescription.replace(/{([^{}]+)}/g, (match, expression) => {
        try {
            return eval(expression);
        } catch (error) {
            console.error(`Error evaluating expression "${expression}":`, error);
            return '';
        }
    });

    return parsedDescription;
};

const parseEventTitle = (event) => {
    const parsed = parseEventText(event, "title").trim();
    if (isEmpty(parsed)) return '';
    return `${parsed}: `;
};

const parseEventDescription = (event) => {
    const parsed = parseEventText(event, "description").trim();
    if (isEmpty(parsed)) return '';
    return parsed;
};


const eventTypeMessageTemplate = (event) => (
    `
        <div style="color: #000050; font-size: 20px; display: inline;">
            ${parseEventTitle(event)}
        </div>
        <div style="font-size: 15px; display: inline;">
            ${parseEventDescription(event)}
        </div>
        <br/>
    `
);

const getEventDivName = (identifier) => `event-${identifier}`;

export { 
    syntaxDocumentation,
    getEventDivName,
    eventTypeMessageTemplate,
    EventTypeStyles,
};
