import React, { createContext, useContext, useMemo, useState } from "react";

const DragsHistoryContext = createContext({});

export const DragsHistoryProvider = ({ children }) => {
	const [historyChanges, setHistoryChanges] = useState([]);

	const contextValue = useMemo(
		() => ({
			historyChanges,
			setHistoryChanges,
		}),
		[historyChanges]
	);

	return (
		<DragsHistoryContext.Provider value={contextValue}>{children}</DragsHistoryContext.Provider>
	);
};

export const useDragsHistoryContext = () => useContext(DragsHistoryContext);
