import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslate } from "react-admin";

import { SelectionDialog } from "./SelectionDialog";

export const ExcludeColumnsModal = ({ resource, selection, toggleColumn, columns }) => {
	const [modalOpened, setModalOpened] = React.useState(false);
	const translate = useTranslate();

	const handleOpen = () => setModalOpened(true);
	const handleClose = () => setModalOpened(false);

	return (
		<>
			<Button variant="text" color="primary" aria-label="add" onClick={handleOpen}>
				{translate("osfc.filters.labels.columns")}
			</Button>
			{modalOpened && (
				<SelectionDialog
					selection={selection}
					columns={columns}
					onColumnClicked={toggleColumn}
					onClose={handleClose}
					resource={resource}
					configurationLabel={translate("osfc.filters.labels.configuration")}
				/>
			)}
		</>
	);
};
