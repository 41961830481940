import EventIcon from '@material-ui/icons/Event';

import { EventsCreate } from './Create/Create';
import { EventsEdit } from './Edit/Edit';
import { EventsList } from './List/List';
import { EventsShow } from './Show/Show';


export {
    EventsList,
    EventsCreate,
    EventsEdit,
    EventsShow,
};

export const eventsResource = {
    list: EventsList,
    edit: EventsEdit,
    create: EventsCreate,
    show: EventsShow,
    icon: EventIcon,
};
