import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, TextInput, useInput, useTranslate } from 'react-admin';

const decodeOptions = (value, options, valueGetter = (v) => v['id']) => {
    return options.filter(option => 
        (value & valueGetter(option)) === valueGetter(option)
    ).map(option => valueGetter(option));
};

const calculateSum = (selectedOptions) => {
    return selectedOptions.reduce((acc, option) => acc + option, 0);
};

const BitwiseAutocompleteArrayInput = (props) => {
    const { 
        source, 
        choices,
        label: labelOverride,
        tempSource: tempSourceOverride, 
        defaultValue = [], 
        optionText = 'name',
        optionValue = 'id',
        onChange,
        ...rest 
    } = props;

    const t = useTranslate();
    const valueGetter = (option) => option[optionValue];

    const tempSource = tempSourceOverride ?? `${source}_temp`;
    let label = source;
    if (labelOverride) {
        label = t(labelOverride);
    } else if (rest.resource) {
        label = t(`resources.${rest.resource}.fields.${source}`);
    };

    const [selectedValues, setSelectedValues] = useState(defaultValue);
    const {
        input: { value: bitwiseValue, onChange: setBitwiseValue },
    } = useInput({ source });
    const {
        input: { value: tempArray, onChange: setTempArray }
    } = useInput({ source: tempSource });

    useEffect(() => {
        if (typeof bitwiseValue === 'number') {
            const decodedArray = decodeOptions(bitwiseValue, choices, valueGetter);
            setSelectedValues(decodedArray);
            setTempArray(decodedArray);
        }
    }, [bitwiseValue, choices, decodeOptions, setTempArray]);

    useEffect(() => {
        if (Array.isArray(tempArray)) {
            const newSum = calculateSum(tempArray);
            if (newSum !== bitwiseValue) {
                setBitwiseValue(newSum);
            }
        }
    }, [tempArray, bitwiseValue, calculateSum, setBitwiseValue]);

    const handleAutocompleteChange = (newSelectedOptions) => {
        const newSum = calculateSum(newSelectedOptions);
        setSelectedValues(newSelectedOptions);
        setBitwiseValue(newSum);
        setTempArray(newSelectedOptions);
        onChange?.(newSum);
    };

    return (
        <>
            <TextInput source={source} style={{ display: 'none' }} />
            <AutocompleteArrayInput
                {...rest}
                choices={choices}
                defaultValue={selectedValues}
                label={label}
                source={tempSource}
                onChange={handleAutocompleteChange}
            />
        </>
    );
};

export { decodeOptions, calculateSum };
export default BitwiseAutocompleteArrayInput;
