import { httpClient } from './httpClient';

export const _getPermissions = () => {
	const rawPermissions = localStorage.getItem('permissions');
	if (!rawPermissions) {
		return null;
	}

	try {
		const permissions = JSON.parse(rawPermissions);
		return permissions;
	} catch (error) {
		console.error('Error on permissions JSON parse:', error);
		return null;
	}
};

export const setPermissions = (permissions) => {
	localStorage.setItem('permissions', JSON.stringify(permissions));
};

// it opens login page on logout and it opens previous page on login
// for all not current active tabs.
window.addEventListener('storage', (e) => {
	if (e.key === 'is_logged_in' && e.newValue === 'true') {
		window.history.back();
	} else if (e.key === 'is_logged_in' && e.newValue !== 'true') {
		window.location = window.location.origin + '/#/login';
	}
});

export const setPermissionsLocale = (locale) => {
	const permissions = _getPermissions();
	permissions.locale = locale;
	setPermissions(permissions);
};

export const getAuthProvider = (updateCustomRoutes = (permissions) => {}) => {
	return {
		login: ({ username, password }) => {
			return httpClient('login', {
				method: 'POST',
				body: JSON.stringify({ email: username, password: password }),
			}).then((res) => {
				localStorage.setItem('is_logged_in', 'true');
				setPermissions(res.json.permissions);
				updateCustomRoutes(res.json.permissions);
				window.dispatchEvent(new Event('storage'));
			});
		},
		logout: (params, ...rest) => {
			return httpClient('logout', {
				method: 'POST',
			})
				.then((res) => {})
				.catch((e) => {})
				.finally(() => {
					localStorage.removeItem('is_logged_in');
					localStorage.removeItem('permissions');
					localStorage.removeItem('events');
					updateCustomRoutes(null);
					window.dispatchEvent(new Event('storage'));
				});
		},
		sendResetPasswordRequest: ({ username }) => {
			return httpClient('/password-requests', {
				method: 'POST',
				body: JSON.stringify({ email: username }),
			});
		},
		resetPassword: ({
			email,
			user_id,
			request_id,
			request_code,
			new_password,
			created_at,
		}) => {
			return httpClient(`/user-passwords/${user_id}`, {
				method: 'PUT',
				body: JSON.stringify({
					reset_password_with_link: true,
					email: email,
					request_id: request_id,
					request_code: request_code,
					created_at: created_at,
					new_password: new_password,
				}),
			});
		},
		checkAuth: (params) => {
			return localStorage.getItem('is_logged_in')
				? Promise.resolve()
				: Promise.reject();
		},
		checkError: (error) => {
			if (error.status === 401) {
				return Promise.reject();
			}
			return Promise.resolve();
		},
		getPermissions: (params) => {
			const permissions = _getPermissions();
			return permissions
				? Promise.resolve(permissions)
				: Promise.reject();
		},
		getIdentity: (params) => {
			const permissions = _getPermissions();
			return permissions
				? Promise.resolve({
						fullName: permissions.fullName || permissions.email,
				  })
				: Promise.reject();
		},
	};
};

export const authProvider = getAuthProvider();
