import React from 'react';

import { usePermissions, useTranslate } from 'react-admin';
import { Link, Route, useRouteMatch, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

import { ChangePasswordBlock } from './ChangePasswordBlock';
import { NotificationsBlock } from './NotificationsBlock';
import { CompanyNotificationsBlock } from './NotificationsBlock';
import { NotificationSettings } from './NotificationSettings';
import { CompanySettingsBlock } from './CompanySettingsBlock';
import { UserSettingsBlock } from './UserSettingsBlock';
import { UserRole, OsfcRoles, AppRoles, AppManagerRoles } from '../../entities/UserRole';
import { CompanySettings } from './CompanySettings';
import { APIKeysBlock } from './APIKeys';
import { Swagger } from '../../pages/developer/';
import { isInArray } from '../../utils/general';


const useStyles = makeStyles(() => ({
	tabs: {
		borderBottom: '1px solid #e0e0e0',
	},
}));

const useChangeTabByUrl = (setTabValue) => {
	let location = useLocation();
	const pathSegments = location.pathname
		.split('/')
		.filter((segment) => segment !== '');
	const lastSegment = pathSegments[pathSegments.length - 1];
	React.useEffect(() => {
		setTabValue(lastSegment);
	}, [lastSegment]);
};

export const Settings = () => {
	const baseTab = 'settings';
	const [value, setValue] = React.useState(baseTab);
	useChangeTabByUrl(setValue);
	const { permissions } = usePermissions();
	const translate = useTranslate();
	let { path, url } = useRouteMatch();
	const classes = useStyles();

	const isNotificationsSettingsActive =permissions && permissions.role != UserRole.app_manager;
	const isOSFC = permissions && OsfcRoles.indexOf(permissions.role) !== -1;
	const isAdmin = permissions && permissions.role === UserRole.admin;
	const isAdminOrOSFC = permissions && AppManagerRoles.indexOf(permissions.role) !== -1;
	const isCustomerManager = permissions && permissions.role === UserRole.customer_manager;
	const isCustomerTransporterManager = permissions?.role === UserRole.customer_and_transporter_manager;

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	if(!permissions){
		return null;
	}
	return (
		<>
			<Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				className={classes.tabs}
			>
				<Tab
					component={Link}
					to={url}
					value={'settings'}
					replace={true}
					label={translate('tab.settings.name')}
				/>
				{isNotificationsSettingsActive && (
					<Tab
						component={Link}
						to={`${url}/notifications`}
						value={'notifications'}
						replace={true}
						label={translate('tab.notifications.name')}
					/>
				)}
				{isAdminOrOSFC && (
					<Tab
						component={Link}
						to={`${url}/notifications-settings`}
						value={'notifications-settings'}
						replace={true}
						label={translate('tab.notifications_settings.name')}
					/>
				)}
				<Tab
					component={Link}
					to={`${url}/change-password`}
					value={'change-password'}
					replace={true}
					label={translate('tab.change_password.name')}
				/>
				{(isAdmin ||
					isCustomerManager ||
					isCustomerTransporterManager) && (
					<Tab
						component={Link}
						to={`${url}/api-keys`}
						value={'api-keys'}
						replace={true}
						label={translate('tab.api.name')}
					/>
				)}
				{(isAdmin ||
					isCustomerManager ||
					isCustomerTransporterManager) && (
					<Tab
						component={Link}
						to={`${url}/documentation`}
						value={'documentation'}
						replace={true}
						label={translate('tab.documentation.name')}
					/>
				)}
			</Tabs>
			{isNotificationsSettingsActive && (
				<Route path={`${path}/notifications`}>
					<Grid item xs={12} md={6} lg={4}>
						<NotificationsBlock />
					</Grid>
				</Route>
			)}
			<Route exact path={`${path}`}>
				<UserSettingsBlock />
			</Route>
			<Route path={`${path}/change-password`}>
				<Grid item xs={12} md={6} lg={4}>
					<ChangePasswordBlock />
				</Grid>
			</Route>

			{isAdminOrOSFC && (
				<Route path={`${path}/notifications-settings`}>
					<Grid item xs={12} md={8} lg={7}>
						<NotificationSettings />
					</Grid>
				</Route>
			)}

			{(isAdmin || isCustomerManager || isCustomerTransporterManager) && (
				<Route path={`${path}/api-keys`}>
					<Grid item xs={12} md={12} lg={12}>
						<APIKeysBlock />
					</Grid>
				</Route>
			)}
			{(isAdmin || isCustomerManager || isCustomerTransporterManager) && (
				<Route path={`${path}/documentation`}>
					<Grid item xs={12} md={12} lg={12}>
						<Swagger />
					</Grid>
				</Route>
			)}
		</>
	);
};

export const Notifications = CompanyNotificationsBlock;
export const CompanySettingsTab = CompanySettingsBlock;
export const CompanySettingsPage = CompanySettings;
