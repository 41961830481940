import React, { Fragment, useState } from "react";
import {
	Button,
	Confirm,
	List,
	TextField,
	TextInput,
	EditButton,
	ReferenceField,
	ReferenceArrayField,
	SingleFieldList,
	ChipField,
	useMutation,
	Loading,
} from "react-admin";
import { useRefresh, useNotify, useTranslate } from "react-admin";
import { useForm } from "react-final-form";
import MUIButton from '@material-ui/core/Button';

import useUpdatePartial from "../../../components/_extensions/react-admin/core/dataProvider/useUpdatePartial";
import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DateTimeField } from "../../../components/fields/DateFormatField";
import { DateTimeInput } from "../../../components/inputs/DateTimeInput";
import { ButtonEditableField } from "../../../components/fields/EditableField";
import { Datagrid } from "../../../components/CustomizableDataGrid/";
import {DraggableContextsWrapper} from "../../../components/DraggableDatagrid";
import DeleteRestoreButtons from "../../../components/toolbars/DeleteRestoreButtons";
import {stylesForColorInheritInList} from "../../../components/_helpers/useStyles";

import { PortAreaFilters, filterDefaultValues} from './ListFilters';
import GridUserDataProvider, { useGridUserDataContext } from "../../../contexts/GridUserDataProvider";
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";
import { Actions } from "./Actions";


const defaultSort = { field: 'id', order: 'DESC' };

const rowStyle = (record) => ({
	backgroundColor: record.disabled ? "rgba(231, 76, 60, 0.3)" : record.disabled_by_order ? "rgba(222, 228, 26, 0.4)" : "rgba(0, 255, 31, 0.3)",
	color: (record.is_deleted) && "#616161"
});

const UnblockButtonInline = () => {
	const form = useForm();
	const t = useTranslate();

	const handleClick = () => {
		form.change({
			lock_datetime_from: null,
			lock_datetime_to: null,
			comment: null,
		});
		form.submit({});
	}

	return (
		<MUIButton onClick={handleClick} variant="text">{t("osfc.buttons.unblock")}</MUIButton>
	);
}

const UnblockButtonPessimWithConfirm = ({basePath, resource, record, entity, disabling, title, disabled_by_order, ...props}) => {
	const refresh = useRefresh();
	const [open, setOpen] = useState(false);
    const notify = useNotify();
    const translate = useTranslate();

    const [update, { loading }] = useUpdatePartial(
        resource,
        record.id,
        disabled_by_order === undefined ? {
            lock_datetime_from: null, 
            lock_datetime_to: null, 
            comment: null,
        }:{disabled_by_order: disabled_by_order},
        record,
        {
            // withDeclarativeSideEffectsSupport: true,
            onSuccess: ({ data }) => {
                refresh();
                notify(translate(`resources.${resource}.confirm_unblock.success`), "info");
            },
            onFailure: (error) => {
                notify(error.message, "error");
            }
        }
    );

    const handleClick = (e) => { e.stopPropagation(); setOpen(true) };
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => { update(); setOpen(false); };
    return (
        <Fragment>
            <Button
                color="primary" variant="outlined" style={{}}
                label={`osfc.buttons.${title}`}
                onClick={handleClick}
                disabled={loading || !record[disabling]}
            />
            <Confirm
                isOpen={open}
                loading={loading}
                title={`resources.${resource}.confirm_unblock.title`}
                content={`resources.${resource}.confirm_unblock.content`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                confirm="osfc.buttons.confirm"
            />
        </Fragment>
    );
}

const UnblockButtonOptim = ({basePath, resource, record, entity, ...props}) => {
    const notify = useNotify();
    const t = useTranslate();
    const [update, { loading }] = useUpdatePartial(
        resource,
        record.id,
        {lock_datetime_from: null, lock_datetime_to: null, comment: null, name: record.name, terminal_id: record.terminal_id},
        record,
        {
            mutationMode: 'undoable',
            // withDeclarativeSideEffectsSupport: true,
            onSuccess: ({ data }) => {
                // refresh();
                notify(t(`resources.${resource}.confirm_unblock.success`), "info", {}, true);
            },
            onFailure: (error) => {
                notify(error.message, "error");
            }
        }
    );
    return (
        <Button
            color="primary" variant="outlined" style={{}}
            label={"osfc.buttons.unblock"}
            onClick={update}
            disabled={loading || !record.disabled}
        />
    );
}

const PrimarySecondaryItems = (props) => {
    const reference = props.entityName === "ports" ? "areas" : "ports";
    return (
        <ReferenceArrayField {...props} reference={reference}>
            <SingleFieldList linkType="edit" style={{ display: "block" }}>
                <ChipField source="name" size="small" />
            </SingleFieldList>
        </ReferenceArrayField>
    );
};

const BlockBlock = (props) => {
	const translate = useTranslate();

    return (
        <ButtonEditableField title={translate("osfc.buttons.block")} color="primary" variant="outlined" style={{}} 
            updateType="updatePartial" {...props}>
            <TerminalDatesInput component={DateTimeInput} priorityRecord={props.record}
                margin="dense" source="lock_datetime_from" label="resources.ports.fields.lock_datetime_from" />
            <TerminalDatesInput component={DateTimeInput} priorityRecord={props.record}
                margin="dense" source="lock_datetime_to" label="resources.ports.fields.lock_datetime_to" />
            <TextInput source="comment" multiline rows={2} label="resources.ports.fields.comment"/>
        </ButtonEditableField>
    );
};

const PortAreaList = props => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const classes = stylesForColorInheritInList();
	const notify = useNotify();

	const [mutate] = useMutation(
		{
			type: "updateCustom",
			resource: "ports-areas-sort-order",
		},
		{
			withDeclarativeSideEffectsSupport: true,
			onSuccess: (props) => {
				const updatedLength = props.data.items.length;
				notify("ra.notification.updated", {
					type: "info",
					messageArgs: { smart_count: updatedLength },
				});
			},
			onFailure: (error) => {
				notify(error.message, "error");
			},
		}
	);

    if (!props.permissions || currentFilters === undefined) {
		return <Loading loadingSecondary="" />;
	};

    const isAreas = props.entityName === "areas";

	const onDragsSave = (data) => {
		mutate({
			payload: {
				id: props.resource,
				data: data,
			},
		});
		
	};
	return (
		<DraggableContextsWrapper isDraggableDefault={false}>
			<List
				{...props}
				exporter={false}
				empty={false}
				bulkActionButtons={false}
				sort={currentSort}
				filters={<PortAreaFilters />}
				filterDefaultValues={currentFilters}
				pagination={null}
				classes={classes}
				actions={<Actions onDragsSave={onDragsSave}/>}
			>
				<ScrollingWrapper>
					<Datagrid
						rowStyle={rowStyle}
						allowDragging
					>
						<TextField source="id" sortable={false} />
						<TextField source="name" sortable={false} />
						<ReferenceField
							source="terminal_id"
							reference="terminal"
							link={false}
							sortable={false}
						>
							<TextField label="Terminal" source="name" />
						</ReferenceField>

						<DateTimeField source="lock_datetime_from" sortable={false} />
						<DateTimeField source="lock_datetime_to" sortable={false} />
						<TextField source="comment" sortable={false} />

						{isAreas ? (
							<ReferenceField
								source="customer_id"
								reference="customer-companies"
								link={false}
								sortable={false}
							>
								<TextField label="Customer" source="full_name" />
							</ReferenceField>
						) : null}

						<PrimarySecondaryItems
							{...props}
							source={props.primaryItems}
							sortable={false}
						/>
						<PrimarySecondaryItems
							{...props}
							source={props.secondaryItems}
							sortable={false}
						/>

						<BlockBlock />
						{/*<UnblockButtonOptim />*/}
						<UnblockButtonPessimWithConfirm disabling="disabled" title="unblock" />
						<UnblockButtonPessimWithConfirm
							disabling="disabled_by_order"
							title="unblock_by_order"
							disabled_by_order={false}
						/>
						<EditButton label="" />
						<DeleteRestoreButtons resourceForUpdate={props.resource} />
					</Datagrid>
				</ScrollingWrapper>
			</List>
		</DraggableContextsWrapper>
	);
}

const PortAreaListWrapper = (props) => (
    <GridUserDataProvider 
        resource={props.resource}
        filterDefaultValues={filterDefaultValues} 
        sortDefaultValues={defaultSort}
    >
        <PortAreaList {...props} />
    </GridUserDataProvider>
);

export const PortList = props => (<PortAreaListWrapper {...props}
                                                entityName={"ports"}
												primaryItems={"primary_areas"}
												secondaryItems={"secondary_areas"}
												secondaryItemsLabel={"Secondary area"} />)
export const AreaList = props => (<PortAreaListWrapper {...props}
                                                entityName={"areas"}
												primaryItems={"primary_ports"}
												secondaryItems={"secondary_ports"}
												secondaryItemsLabel={"Secondary ports"}/>)