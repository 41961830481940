import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  CloneButton,
  RichTextField,
  ReferenceField,
  FunctionField
} from 'react-admin';

import { DateTimeField } from '../../../components/fields/DateFormatField';
import { LongTextField } from '../../../components/fields/LongTextField';
import { LinkFieldToOther } from '../../../components/fields/LinkField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { NotifStatus } from '../../../entities/NotifStatus';
import { ResendButton } from '../Buttons/ResendButton';
import CustomerField from '../../../components/fields/CustomerField';
import Filters from '../Filter/Filters';
import { NotifType } from '../../../entities/NotifType';

const getStatusColorClass = status => {
  switch (status) {
    case 1:
      return '#FFFFFF';
    case 2:
      return '#00AFF3';
    case 3:
      return '#b7ff7d';
    case 4:
      return '#ff8c8c';
    default:
      return '#000000';
  }
}

const rowStyle = (record) => ({
  backgroundColor: getStatusColorClass(record.status),
});

const defaultSort = {
  field: 'time',
  order: 'DESC',
};

const VisibleWrapper = ({ component: Component, ...props}) => {
  if (![NotifType.email, NotifType.sms].includes(props.record["type"])) {
    return null;
  }
  return <Component {...props} />;
}

const NotifLogs = ({ permissions, ...props }) => (
  <List {...props}
    exporter={false}
    bulkActionButtons={false}
    sort={defaultSort}
    filters={<Filters />}>
    <Datagrid rowStyle={rowStyle}>        
      <TextField source="id" />
      {/* <TextField source="order_id" /> */}
      <SelectEnumField source="type" enumName="notif.type" enumObj={NotifType} sortable={false}/>
      <LinkFieldToOther source="order_id" otherResource="orders" otherIdProp="order_id" />

      {/* <TextField source="sender" /> */}
      
      {/* <DateTimeField source="time" withLocalTimeZone /> */}
      <TextField source="recipient" sortable={false}/>
      <TextField source="recipient_name" />
      <CustomerField source="recipient_company" formatLabel={(record) => record["recipient_company_name"]} sortable={false}/>

      <TextField source="text" style={{ whiteSpace: "normal" }} sortable={false}/>
      <SelectEnumField source="status" enumName="status" enumObj={NotifStatus} sortable={false}/>
      <DateTimeField label="created" source="time" withLocalTimeZone />
      <DateTimeField label="sent" source="time_sent" withLocalTimeZone />
      
      <LinkFieldToOther source="customer_name" otherResource="customer-companies" otherIdProp="customer_id" />
      <CustomerField source="eta_3rd_party" formatLabel={(record) => record["eta_3rd_party_name"]}/>		
      <CustomerField source="etd_3rd_party" formatLabel={(record) => record["etd_3rd_party_name"]}/>		

      <VisibleWrapper component={CloneButton} />
      <VisibleWrapper component={ResendButton} />

    </Datagrid>
  </List>
);

export default NotifLogs;