import React from "react";
import { Filter, Loading } from "react-admin";
import { MonthRangeFilter } from "../../../components/filters/MonthRangeFilter";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "ra-core";

const getCreatedByApiCount = (data) => {
	let totalLength = 0;
	for (let key in data) {
		if (data[key] && Array.isArray(data[key]["order_ids"])) {
			totalLength += data[key]["order_ids"].length;
		}
	}
	return totalLength;
};


export const CreatedByApiCount = (props) => {
	const translate = useTranslate();
	if (!props.data) {
		return <Loading loadingSecondary="" />;
	}
	const totalLength = getCreatedByApiCount(props.data);

	return (
		<Box component="span" m={1}>
			<Typography variant="body2" color="secondary">
				{translate("resources.orders-by-api-keys.created_count")}
				{totalLength}
			</Typography>
		</Box>
	);
};

export const ListFilter = (props) => {
	return (
		<Filter {...props}>
			<MonthRangeFilter fromSource="completed_at_from" toSource="completed_at_to" alwaysOn />
			<CreatedByApiCount alwaysOn {...props} />
		</Filter>
	);
};
