import React, { createContext, useContext, useMemo, useState } from "react";

const IsDraggableContext = createContext({});

const IsDraggableProvider = ({ children, isDraggableDefault = false }) => {
	const [isDraggable, setIsDraggable] = useState(isDraggableDefault);

	const contextValue = useMemo(
		() => ({
			isDraggable,
			setIsDraggable,
		}),
		[isDraggable]
	);

	return (
		<IsDraggableContext.Provider value={contextValue}>{children}</IsDraggableContext.Provider>
	);
};

const useIsDraggableContext = () => useContext(IsDraggableContext);

export { IsDraggableProvider, useIsDraggableContext };
