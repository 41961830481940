import React from "react";

import {
	useTranslate,
	useRefresh,
	useListContext,
	useNotify,
	useMutation,
} from "react-admin";

export const HandleOnDragEndWrapper = ({children, ...rest }) => {
	const translate = useTranslate();
	const t = (field) => translate(`resources.orders.fields.${field}`);
	const refresh = useRefresh();
	const notify = useNotify();
	const { data } = useListContext(rest);
	const [mutate] = useMutation(
		{
			type: "updatePartial",
			resource: "orders",
		},
		{
			withDeclarativeSideEffectsSupport: true,
			onSuccess: () => {
				refresh();
				notify(translate("ra.notification.updated", { smart_count: 1 }), {
					type: "info",
					messageArgs: { smart_count: 1 },
				});
			},
			onFailure: (error) => {
				notify(error.message, "error");
			},
		}
	);

	const handleOnDragEnd = ({ draggableIDs, destination, draggableId }) => {
		const foundRecordId = draggableIDs[destination.index];
		const dropRecord = data[foundRecordId];
		const dragRecord = data[draggableId];

		if (dragRecord.rank !== dropRecord.rank) {
			mutate({
				payload: {
					id: draggableId,
					data: { rank: dropRecord.rank },
				},
			});
		}
	};
	return <>{React.cloneElement(children, { handleOnDragEnd: handleOnDragEnd, ...rest })}</>;
};
