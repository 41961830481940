import React from "react";
import { useTranslate } from "react-admin";
import { ArrayChipField } from "../../../components/fields/ArrayChipField";
import { ModalDialog } from "../../../components/ModalDialog";

export const ReferenceArrayDialog = ({ record }) => {
	const translate = useTranslate();
	return (
		<ModalDialog
			title={
				<span>
					{translate("osfc.dialogs.invoices.title")} [{record.date_from}, {record.date_to}
					]
				</span>
			}
			content={<ArrayChipField resource={"orders"} ids={record["order_ids"]} />}
		/>
	);
};
