import React from "react";

import { List, Datagrid, ReferenceField, TextField, EditButton, Pagination } from 'react-admin';

import { AppManagerRoles, AppRoles, UserRole } from '../../../entities/';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { StyledTransportField } from '../../../components/fields/StyledEmailField';
import { DatagridWithBlockDeleteProps } from "../../../components/_helpers/props/DatagridProps";
import { stylesForColorInheritInList } from "../../../components/_helpers/useStyles";
import DeleteRestoreButtons from '../../../components/toolbars/DeleteRestoreButtons';

import { TrailersFilter } from './ListFilters';
import { TrucksActions } from './ListActions';
import { isInArray } from "../../../utils/general";


const defaultSort = { field: 'name', order: 'ASC' };
const TrailerPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

export const TrailersList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList();

	const isAdminOrOSFCManager = permissions && isInArray(AppManagerRoles, permissions.role);
	const isAdmin = permissions && isInArray(AppRoles, permissions.role);

	return (
		<List
			{...props}
			sort={defaultSort}
			pagination={<TrailerPagination/>}
			exporter={false}
			bulkActionButtons={false}
			classes={classes}
			filters={<TrailersFilter hasCompanyFilter={isAdminOrOSFCManager} />}
			actions={<TrucksActions />}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockDeleteProps}
					rowClick="show"
				>
					<TextField source="id" />
					{isAdminOrOSFCManager && <ReferenceField source="company_id" reference="companies" link={false}>
						<TextField source="full_name" />
					</ReferenceField>}
					<StyledTransportField source="name" />
					{ props.hasEdit && <EditButton />}
					{!isAdmin && (
						<DeleteRestoreButtons
							resource={props.resource}
							confirmDeleteStringKey={"osfc.dialogs.confirm_delete_transport"}
							confirmRestoreStringKey={"osfc.dialogs.confirm_restore_transport"}
							requestMethod={"delete"}
						/>
					)}
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
