import React from "react";
import T from "prop-types";

import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import get from "lodash/get";

import DisableBulkIcon from "@material-ui/icons/CheckBox";
import EnableBulkIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import IconButton from "@material-ui/core/IconButton";

import { ColumnSelectionForm, EXCLUDE } from "./SelectionDialog";
import { ExcludeColumnsModal } from "./ExcludeColumnsModal";
import DatagridEx from "../../components/_extensions/react-admin/ra-ui-materialui/src/list/datagrid/DatagridEx";
import LocalStorage from './LocalStorage';

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored on server
const getColumnNames = (children) => {
	return filter(React.Children.map(children, (field) => get(field, ["props", "source"])));
};

const getColumnLabels = (children) => {
	return filter(
		React.Children.map(
			children,
			(field) =>
				field && {
					source: get(field, ["props", "source"]),
					label: get(field, ["props", "label"]),
				}
		),
		(item) => item && item.source
	);
};

const filterExcludedColumns = (excludedColumns, children) => {
	const excludedColumnsKeys = Object.keys(excludedColumns);
	const datagridColumns = getColumnNames(children);

	for (let i = 0; i < excludedColumnsKeys.length; i++) {
		if (
			excludedColumns[excludedColumnsKeys[i]] !== EXCLUDE ||
			datagridColumns.indexOf(excludedColumnsKeys[i]) < 0
		) {
			delete excludedColumns[excludedColumnsKeys[i]];
		}
	}
};
const getExcludedColumns = (storage, resource, children) => {
	const excludedColumns = storage.get(resource) || {};
	filterExcludedColumns(excludedColumns, children);

	if (!isEmpty(excludedColumns)) {
		return excludedColumns;
	}

	return {};
};

export const CustomizableDatagrid = ({ children, storage, ...props }) => {
	const { resource, filterValues, exposedColumns = [], canMultipleChanges, ...rest } = props;
	const [excludedColumns, setExcludedColumns] = React.useState(
		getExcludedColumns(storage, resource, children)
	);

	React.useEffect(() => {
		if (excludedColumns) {
			storage.set(resource, excludedColumns);
		}
	}, [excludedColumns]);

	const toggleColumn = (columnName) => {
		const newState = { ...excludedColumns };
		if (newState[columnName] === EXCLUDE) {
			delete newState[columnName];
		} else {
			newState[columnName] = EXCLUDE;
		}
		setExcludedColumns(newState);
	};
	const handleSetBulkOpen = () => props.setIsBulkOpen(!props.isBulkOpen);

	const renderChild = (child) => {
		if (child) {
			const source = get(child, ["props", "source"]);
			// Show children without source, or children explicitly visible
			if (source && excludedColumns[source] === EXCLUDE) {
				return null;
			}
			return React.cloneElement(child, {});
		}
		return null;
	};
	return (
		<div>
			<div id={"aboveTable"} style={{ display: "flex", alignItems: "center" }}>
				{canMultipleChanges ? (
					<IconButton
						color="primary"
						onClick={handleSetBulkOpen}
						style={{ padding: "12px 16px" }}
					>
						{props.isBulkOpen ? <EnableBulkIcon /> : <DisableBulkIcon />}
					</IconButton>
				) : null}
				<ExcludeColumnsModal
					resource={resource}
					selection={excludedColumns}
					columns={getColumnLabels(children)}
					toggleColumn={toggleColumn}
				/>
				{exposedColumns && (
					<ColumnSelectionForm
						formGroupProps={{ row: true, style: { marginLeft: "10px" } }}
						columns={getColumnLabels(children)}
						selection={excludedColumns}
						onColumnClicked={toggleColumn}
						resource={resource}
						filterColumns={exposedColumns}
					/>
				)}
			</div>
			<DatagridEx {...rest}>{React.Children.map(children, renderChild)}</DatagridEx>
		</div>
	);
};

CustomizableDatagrid.propTypes = {
	storage: T.shape({
		get: T.func.isRequired,
		set: T.func.isRequired,
	}),
	resource: T.string,

};

CustomizableDatagrid.defaultProps = {
	storage: LocalStorage,
};
