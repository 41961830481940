import React from 'react';

import { useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	contentRoot: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',

		padding: theme.spacing(1),
		height: 166,

		'&:last-child': {
			paddingBottom: theme.spacing(1),
		},
	},
	progress: {

	},
}));


export const DocumentAddButton = ({ uploadFile, uploading }) => {
	const translate = useTranslate();
	const classes = useStyles();
	const inputRef = React.useRef(null);
	const handleSelectFile = React.useCallback(() => inputRef.current.click(), []);

	const handleFileChange = React.useCallback(async (e) => {
		uploadFile(e.target.files[0])
		console.log(e.target.files[0])
	}, [ uploadFile ]);

	return (
		<React.Fragment>
			<Button
				color="primary"
				disabled={uploading}
				startIcon={uploading ? <CircularProgress size={16} thickness={5} className={classes.progress} /> : <AddIcon />}
				onClick={handleSelectFile}
			>
				<input
					ref={inputRef}
					onChange={handleFileChange}
					type="file"
					id="file"
					style={{display: "none"}}
					accept=".png,.jpg,.jpeg,.webp,.pdf,.docx,.doc,.xlsx,.xls"
				/>
				{translate("osfc.fields.document.add_document")}
			</Button>
		</React.Fragment>
	);
}
