import React from "react";
import { Button } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	dialogContent: {
		padding: "0",
	},
});

const ShowButton = (props) => (
	<Button
		{...props}
		label="osfc.buttons.show"
		variant="outlined"
		color="secondary"
		style={{ paddingRight: "12px" }}
	/>
);

export const ModalDialog = ({ button: ButtonComponent = ShowButton, title, content }) => {
	const classes = useStyles();

	const [isOpenedPopover, setIsOpenedPopover] = React.useState(false);
	const handleClick = (e) => {
		setIsOpenedPopover(true);
		e.preventDefault();
		e.stopPropagation();
	};
	const handleClose = (e) => {
		setIsOpenedPopover(false);
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<>
			<ButtonComponent onClick={handleClick} />
			<Dialog open={isOpenedPopover} onClose={handleClose}>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent className={classes.dialogContent}>{content}</DialogContent>
			</Dialog>
		</>
	);
};
