import React from "react";
import { List, Datagrid, TextField, Loading, ReferenceField } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { PriceEditableField } from '../../../components/fields/PriceField';

import { SpecialPricesField } from './SpecialPriceField';

import { ArticlesFilters, filterDefaultValues } from './ListFilters';
import { ArticlesActions } from "./Actions";
import GridUserDataProvider, { useGridUserDataContext } from "../../../contexts/GridUserDataProvider";

const defaultSort = { field: 'code', order: 'DESC' };


const articleRowStyle = (record) => {				
	return {
		backgroundColor: record.color,
	};
};


export const _ArticlesList = props => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const [selectedIds, setSelectedIds] = React.useState([]);
	const [selectedFields, setSelectedFields] = React.useState({});
	const [defaultData, setDefaultData] = React.useState(null);
	const [isEdit, setIsEdit] = React.useState(false);

	if (!props.permissions || currentFilters === undefined) {
		return <Loading loadingSecondary="" />;
	};
	const onEdit = () => {
		setIsEdit(true);
	}
    const updateSelectedIds = (newId) => {
		setSelectedIds(prevSelectedIds => {
			if (prevSelectedIds.includes(newId)) {
				return prevSelectedIds; 
			}
			return [...prevSelectedIds, newId];
		});
    };
	const updateSelectedFields = (newField, source) => {
		setSelectedFields(prevFields => {
			prevFields[newField.id] = {...prevFields[newField.id], [source]: newField[source]};
			return prevFields;
		});
    };
	const updateSelectedFieldsSpecialPrice = (recordId, specialPrices) => {
		setSelectedFields(prevFields => {
			prevFields[recordId] = {...prevFields[recordId], "special_price": specialPrices};
			return prevFields;
		});
    };
	
	const cancelEdit = () => {
		setSelectedIds([]);
		setSelectedFields({});
		setIsEdit(false);
	}

	return (
		<List
			{...props}
			exporter={false}
			empty={false}
			bulkActionButtons={false}
			sort={currentSort}
			pagination={null}
			filterDefaultValues={currentFilters}
			filters={<ArticlesFilters />}
			actions={props.options.hasUpload ? <ArticlesActions selectedIds={selectedIds} cancelEdit={cancelEdit}
					selectedFields={selectedFields} onEdit={onEdit} isEdit={isEdit} setDefaultData={setDefaultData} 
					defaultData={defaultData} permissions={props.permissions}/> : null}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					// rowClick="show"
					rowStyle={articleRowStyle}
					rowClick={null}
					size="small"
				>
					{/* <TextField source="id" sortable={false} /> */}
					<TextField source="code" sortable={false} />

                    <ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
                        <TextField label="Terminal" source="name" />
                    </ReferenceField>

					<TextField source="name" sortable={false} />
					<TextField source="calculation" sortable={false} />
					<PriceEditableField source="price_a" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
					<PriceEditableField source="price_b" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
					<PriceEditableField source="price_c" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
					<PriceEditableField source="price_d" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
					<PriceEditableField source="price_e" updateSelectedIds={updateSelectedIds} updateSelectedFields={updateSelectedFields}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit} sortable={false} />
					<TextField source="service_description" sortable={false} />
					<SpecialPricesField source="special_prices" sortable={false} updateSelectedIds={updateSelectedIds} updateSelectedFieldsSpecialPrice={updateSelectedFieldsSpecialPrice}
						selectedIds={selectedIds} selectedFields={selectedFields} editable={isEdit}/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};

export const ArticlesList = (props) => (
	<GridUserDataProvider 
		resource={props.resource}
		filterDefaultValues={filterDefaultValues} 
		sortDefaultValues={defaultSort}
	>
		<_ArticlesList {...props} />
	</GridUserDataProvider>
);
