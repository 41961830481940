import React from "react";
import { CreateButton, useListContext } from "react-admin";
import Box from "@material-ui/core/Box";

import { DragAndDropActionsButtons } from "./DragAndDropActionsButtons";

export const Actions = ({ onDragsSave, ...props }) => {
	const {
		currentSort,
		resource,
		displayedFilters,
		filterValues,
		hasCreate,
		basePath,
		selectedIds,
		showFilter,
		total,
	} = useListContext(props);

	return React.useMemo(
		() => (
			<Box sx={{ display: "flex", gap: 16 }}>
				<DragAndDropActionsButtons filterValues={filterValues} onDragsSave={onDragsSave} />
				{hasCreate && <CreateButton basePath={basePath} />}
			</Box>
		),
		[resource, displayedFilters, filterValues, selectedIds, total]
	);
};
