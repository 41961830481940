import React from 'react';

import { Filter, useTranslate } from 'react-admin';

import TextInputEx from '../../../components/_extensions/react-admin/input/TextInputEx';

import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { EventsType } from '../../../entities/EventsType';
import { DateTimeInput } from '../../../components/inputs';
import { EventStatus } from '../../../entities/EventStatus';


export const EventsFilter = (props) => {
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SelectEnumInput
                resettable
                source='type'
                enumName='events_type' 
                enumObj={EventsType} 
            />

            <SelectEnumInput
                resettable
                source='status'
                enumName='events_status' 
                enumObj={EventStatus} 
            />

            <DateTimeInput source='start_datetime.start_datetime_from' label={translate('osfc.filters.start_datetime.from')} />
            <DateTimeInput source='start_datetime.start_datetime_to' label={translate('osfc.filters.start_datetime.to')} />
            <DateTimeInput source='end_datetime.end_datetime_from' label={translate('osfc.filters.end_datetime.from')} />
            <DateTimeInput source='end_datetime.end_datetime_to' label={translate('osfc.filters.end_datetime.to')} />

            <TextInputEx source='title' />
            <TextInputEx source='description' />
        </Filter>
    );
};
