import React, { createContext, useContext, useMemo, useState } from "react";

const DraggableIDsContext = createContext({});

export const DraggableIDsProvider = ({ children }) => {
	const [draggableIDs, setDraggableIDs] = useState(null);


	const contextValue = useMemo(
		() => ({
			draggableIDs,
			setDraggableIDs,
		}),
		[draggableIDs]
	);

	return (
		<DraggableIDsContext.Provider value={contextValue}>{children}</DraggableIDsContext.Provider>
	);
};

export const useDraggableIDsContext = () => useContext(DraggableIDsContext);
