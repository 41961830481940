import React from 'react';

import { useTranslate } from 'react-admin';
import { JsonField } from "react-admin-json-view";
import Typography from '@material-ui/core/Typography';


export const defaultReactJsonOptions ={
    name: null,
    collapsed: false,
    enableClipboard: false,
    displayDataTypes: false,
    style: {
        padding: "8px 0 4px"
    }
}

export const JsonTextField = ({label, jsonString=true, reactJsonOptions=defaultReactJsonOptions, ...props}) => {
  const translate = useTranslate()

  return (
        <>
            <Typography variant="caption" component="span" color="textSecondary">
            {translate(label)}
      </Typography>
      <JsonField
        {...props}
                jsonString={jsonString} 
        reactJsonOptions={reactJsonOptions}
      />
        </>
  );
};