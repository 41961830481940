import React from "react";
import { List, Datagrid, ReferenceField, TextField, Loading } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DatagridWithBlockProps } from '../../../components/_helpers/props/DatagridProps';
import { PriceField } from '../../../components/fields/PriceField';

import { CustomerArticlesFilters, filterDefaultValues } from './ListFilters';
import GridUserDataProvider, { useGridUserDataContext } from "../../../contexts/GridUserDataProvider";


const defaultSort = { field: 'code', order: 'DESC' };


const articleRowStyle = (record) => {				
	return {
		backgroundColor: record.color,
	};
};

const _ArticlesList = props => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();

	if (!props.permissions || currentFilters === undefined) {
		return <Loading loadingSecondary="" />;
	};

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			sort={currentSort}
			pagination={null}
			filterDefaultValues={currentFilters}
			filters={<CustomerArticlesFilters />}
			actions={null}
		>
			<ScrollingWrapper>
				<Datagrid
					{...DatagridWithBlockProps}
					rowStyle={articleRowStyle}
					rowClick={null}
					size="small"
				>
					<TextField source="id" sortable={false} />
					<TextField source="code" sortable={false} />

                    <ReferenceField source="terminal_id" reference="terminal" link={false} sortable={false}>
                        <TextField label="Terminal" source="name" />
                    </ReferenceField>

					<TextField source="name" sortable={false} />
					<TextField source="calculation" sortable={false} />
					<PriceField source="price" sortable={false} />
					<TextField source="service_description" sortable={false} />
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
}

export const ArticlesList = (props) => (
	<GridUserDataProvider 
		resource={props.resource}
		filterDefaultValues={filterDefaultValues} 
		sortDefaultValues={defaultSort}
	>
		<_ArticlesList {...props} />
	</GridUserDataProvider>
);
