export const SysNotifType = {
	none: 0,
	email: 1, 
	sms: 2, 
	mobile_push: 4, 
};


export const SysNotifTypeChoices = [
	{
		id: SysNotifType.email, 
		name: 'email', 
	}, 
	{
		id: SysNotifType.sms, 
		name: 'sms', 
	}, 
	{
		id: SysNotifType.mobile_push, 
		name: 'mobile_push', 
	}, 
];

export const SysNotifTypeRepresentChoices = [
	{
		id: SysNotifType.none, 
		name: 'none', 
	}, 
	...SysNotifTypeChoices,
];

export const SysNotifTypeTranslationChoices = SysNotifTypeChoices.map(
	el => ({id: el.id, name: `osfc.enums.sysnotiftype.${el.name}`}));

export const SysNotifTypeRepresentTranslationChoices = SysNotifTypeRepresentChoices.map(
	el => ({id: el.id, name: `osfc.enums.sysnotiftype.${el.name}`}));


export const SysNotifEvents = {
	assign: 1, 
	slot: 2, 
	both: 3, 
};


export const SysNotifEventsChoices = [
	{
		id: 0, 
		name: 'none', 
	}, 
	{
		id: 1, 
		name: 'assign', 
	}, 
	{
		id: 2, 
		name: 'slot', 
	},
	{
		id: 3, 
		name: 'both', 
	}, 
];
