import React, {  useEffect, useCallback, useState} from "react";

import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import { TextInput, ReferenceInput, usePermissions, SelectInput, maxLength, useTranslate, useDataProvider } from 'react-admin';
import {useForm, useFormState} from 'react-final-form';

import { required } from '../../../utils/validation/required';
import { requiredIfNoFieldValue } from '../../../utils/validation/requiredIfNoFieldValue';
import { validatePlaceLength } from "../../../utils/validation/order";
import { GridForm, FormGroupGrid, GridInput } from '../../../components/GridForm';
import { DateInput, TimeInput, CompanySelect, NumberInputEx } from '../../../components/inputs';
import { SelectUppercaseInput } from '../../../components/inputs/SelectUppercaseInput';
import { YesNoInput } from '../../../components/inputs/YesNoInput';
import { SelectTextInput } from '../../../components/inputs/SelectTextInput';
import { PhoneInput } from '../../../components/inputs';
import { formatPhoneNumber } from '../../../components/fields/PhoneField';
import { PortInput } from "../../../components/inputs/PortInput";

import { OrderService, UserRole, OrderStatus, OrderStatusCustomerEditOrder, OrderStatusTransporterEditOrder_1, OrderStatusTransporterEditOrder_2, DriverRoles } from '../../../entities';

import { OrderReferenceInput } from '../../../components/inputs/OrderReferenceInput';

import { ServiceInput } from './inputs/ServiceInput';
import { CommodityInput } from './inputs/CommodityInput';
import { TripNumberInput } from './inputs/TripNumberInput';
import { LoadingOrderInput } from './inputs/LoadingOrderInput';
import { PrintedDocsInput } from "./inputs/PrintedDocsInput";

import { addHoursToDate } from "../../../components/_helpers/dateTime";

import {
	DimensionsBlock, 
	ETAETDFormGroup, 
	DangerTextInputForInternalNotes, 
	useDriverChange, 
	useEtaEtdSync, 
	etaEtdPorts, 
	driversSelector
} from './shared'

import { LabelSource } from "../../../entities/LabelSource";
import { SelectEnumInput } from "../../../components/inputs/SelectEnumInput";
import { getNow } from "../../../utils/datetime";
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";


export const ETAETDBlock = ({isAddMode, prefix, canEditOnlyTime, ...props}) => {
	const { permissions } = usePermissions();	
	const { values } = useFormState();	
	const handleEtaEtdSync = useEtaEtdSync(prefix);
	const handleDriverChange = useDriverChange(prefix);
	// const drivers = useSelector(driversSelector);

	if (!permissions) return null;
	
	const userId = permissions.id;
	const userCompanyId = permissions.company_id;

	if (!(
		isAddMode ||
		userCompanyId === values[`${prefix}_3rd_party`] ||
		(userCompanyId === values['company_id'] && userId === values[`${prefix}_driver_id`])
	)){	
		return <Grid item {...props} />;
	}

	// const companyId = values.company_id;
	// const isTimeSelected = !!values[`${prefix}_time`];
	const isManager = (permissions.role === UserRole.transporter_manager) || 
					  (permissions.role === UserRole.customer_and_transporter_manager);
	const isEmployee = (permissions.role === UserRole.transporter_employee) || 
					  (permissions.role === UserRole.customer_and_transporter_employee);
	const isDriver =  (permissions.role === UserRole.transporter_driver) || 
					  (permissions.role === UserRole.customer_and_transporter_driver);
	const isTransporterSelected = !!values[`${prefix}_3rd_party`];
	const isOwnCompany = values['company_id'] === userCompanyId;

	const isDisabledTimeForEditing = isDriver ?
		!((isTransporterSelected || isOwnCompany) && values[`${prefix}_driver_id`] === userId) :
		!(isTransporterSelected && values[`${prefix}_3rd_party`] === userCompanyId);
	const isDisabledForEditing = canEditOnlyTime || isDisabledTimeForEditing;
	const isIntoPlukkStorage = values.service === OrderService.intoPlukkStorage;

	const dateRequiredValueKeyPrefix = prefix === 'eta' ? 'etd' : 'eta';
	const portKey = etaEtdPorts[prefix];
	const currentTransportFilter = { no_range: true, is_deleted: false };
	const currentTransportSort = { field: "name", order: "ASC" };
	if (isManager) {
		// const currentDriver = drivers[values[`${prefix}_driver_id`]];
		// if (currentDriver !== undefined) {
		// 	currentTransportFilter.company_id = drivers[values[`${prefix}_driver_id`]].company_id;
		// };
		currentTransportFilter.company_id = userCompanyId
	}
	
	// const currentTransporter = values[`${prefix}_3rd_party`];
	const currentDriverFilter = {
		// company_id: currentTransporter,
		no_range: true,
		company_id: userCompanyId,
		is_blocked: false,
		is_deleted: false,
	};
	const currentDriverSort = { field: "first_name", order: "ASC" };
	const placePrefix = prefix === "eta" ? "from" : "to";

	return (
		<ETAETDFormGroup {...props} prefix={prefix}>
			<FormGroupGrid>
				<GridInput xs={6} sm={3}>
					<TerminalDatesInput component={DateInput} source={`${prefix}_date`} disabled={isDisabledForEditing} validate={[isIntoPlukkStorage ? requiredIfNoFieldValue(`${dateRequiredValueKeyPrefix}_date`) : required()]} onChange={handleEtaEtdSync} />
				</GridInput>
				<GridInput xs={6} sm={3}>
					<TerminalDatesInput component={TimeInput} source={`${prefix}_time`} disabled={isDisabledTimeForEditing} />
				</GridInput>
				<GridInput xs={6} sm={3} component={TextInput} source={`${prefix}_slot_time`} disabled />
				<GridInput xs={6} sm={3} component={PortInput} source={portKey} disabled />
			</FormGroupGrid>
			<FormGroupGrid>
				<GridInput  xs={12} sm={12} md={12} component={TextInput} source={`place_${placePrefix}`} validate={[validatePlaceLength]} disabled={isDisabledForEditing} />
				{(isManager || isEmployee) && <GridInput xs={12} sm={6} md={6} component={SelectTextInput} sourceText={`${prefix}_driver`} sourceSelect={`${prefix}_driver_id`} reference="drivers" 
											   optionText={record => `${record.first_name} ${record.last_name} | ${formatPhoneNumber(record.phone)}`}
											   filter={currentDriverFilter} sort={currentDriverSort} disabled={isDisabledForEditing}
											   validate={[maxLength(64)]} onChange={handleDriverChange} />}
				{!isDisabledForEditing && <GridInput xs={12} sm={6} md={6} component={PhoneInput} source={`${prefix}_driver_phone`} disabled={isDisabledForEditing} />}
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_truck"} 
								 sourceText={`${prefix}_truck`} sourceSelect={`${prefix}_truck_id`} prefix={prefix} reference="trucks"
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
				<GridInput xs={12} sm={6} md={6} component={SelectTextInput} label={"resources.orders.fields." + prefix + "_trailer"}
								 sourceText={`${prefix}_trailer`} sourceSelect={`${prefix}_trailer_id`} reference="trailers"
								 filter={currentTransportFilter} sort={currentTransportSort} disabled={isDisabledForEditing} validate={[maxLength(64)]} />
			</FormGroupGrid>
		</ETAETDFormGroup>
	);
}


export const FormTransporter = ({isAddMode, ...props}) => {
	const form = useForm();

	const { permissions } = usePermissions();
	const { values } = useFormState();
	const translate = useTranslate()
	const dataProvider = useDataProvider();
	const [canEditOnlyTime, setCanEditOnlyTime] = useState(false);

	useEffect(()=>{
        if (permissions && DriverRoles.indexOf(permissions.role) !== -1){
            dataProvider.getOne('drivers', { id: permissions.id })
            .then((result) => {
                setCanEditOnlyTime(result.data.can_edit_only_time);
            })
        }
    }, [permissions])
	
	if (!permissions) return null;

	const status = props.record.status;

	const isDriver = (permissions.role === UserRole.transporter_driver) || 
					  (permissions.role === UserRole.customer_and_transporter_driver);
	let enabledFields;
	if (!isAddMode){		
		if (OrderStatusTransporterEditOrder_2.indexOf(status) !== -1) {
		} else if (status === OrderStatus.ORDER_COMPLETED || status === OrderStatus.DELETED){
			if (!isDriver){
				enabledFields = {};
			}else{
				const orderCompletedAt = new Date(values['completed_at']+'Z')
				const datetimeNow = getNow(2)
				if (datetimeNow > addHoursToDate(orderCompletedAt, 48)){
					enabledFields = {};
				}
			}
		} else {
			enabledFields = {notes: true, internal_notes: true};
		}
	}
	// const handleLabelSourceChange = (event) =>{
	// 	const val = event.target.value
	// 	if(val == LabelSource.pallets){
	// 		form.change("label", values.pallets)
	// 	} else if(val == LabelSource.boxes){
	// 		form.change("label", values.boxes)
	// 	}
	// }

	let loadingOrderDisable = true;
	if (isAddMode || OrderStatusTransporterEditOrder_1.indexOf(status) !== -1){		
		loadingOrderDisable = permissions.company_id !== values.etd_3rd_party || (DriverRoles.indexOf(permissions.role) !== -1 && canEditOnlyTime);
	}

	return (
		<GridForm {...props} enabledFields={enabledFields}>
			<FormGroupGrid>
				<GridInput source="company_id" sm={2} component={CompanySelect} disabled />
				<GridInput source="reference" sm={2} component={OrderReferenceInput} validate={[required()]} disabled />
				<GridInput source="service" sm={2} component={ServiceInput} validate={[required()]} defaultValue={OrderService.reloadCarCar} disabled />
				<GridInput sm={3} disabled>
					<ReferenceInput source="status" reference="order-statuses" validate={[required()]}>
						<SelectInput optionText="name" />
					</ReferenceInput>
				</GridInput>
				<GridInput sm={3} component={YesNoInput} source="priority" defaultValue={false} disabled />
					{/* <GridInput sm={2} component={LoadNoInput} source="load_no" disabled /> */}
			</FormGroupGrid>

			<FormGroupGrid xs={12}>
				<ETAETDBlock sm={12} md={12} lg={6} prefix="eta" canEditOnlyTime={canEditOnlyTime} {...props} enabledFields={enabledFields} />
				<ETAETDBlock sm={12} md={12} lg={6} prefix="etd" canEditOnlyTime={canEditOnlyTime} {...props} enabledFields={enabledFields} />
			</FormGroupGrid>
			
			{/* <FormGroupGrid>
				<GridInput sm={3} component={YesNoInput} source="split_load" defaultValue={false} disabled />
				<GridInput sm={3} component={TripNumberInput} source="trip_number" disabled />
				<GridInput sm={3} component={LoadingOrderInput} source="loading_order" disabled />
			</FormGroupGrid>

			<FormGroupGrid>
				<GridInput sm={3} hidden={values.service === OrderService.reloadCarCar}>
					<ReferenceInput source="cooler_type_id" reference="cooler-types" filter={{ order_service: values.service }}>
						<SelectUppercaseInput optionText="name" disabled />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={3} component={YesNoInput} source="plumb_seal" defaultValue={false} disabled />
				<GridInput xs={6} sm={3} component={YesNoInput} source="printed_doc" defaultValue={false} disabled />
				<GridInput sm={3} component={CommodityInput} source="commodity" disabled />
			</FormGroupGrid> */}


			{/* <Grid item xs={12} md={6} lg={4}> */}
				<FormGroupGrid xs={12} md={6} lg={4} title={translate("resources.orders.block-label.split-loads")} {...props}>
					<GridInput xs={4} component={YesNoInput} source="split_load" defaultValue={false} disabled />
					<GridInput xs={4} component={TripNumberInput} source="trip_number" disabled />
					<GridInput xs={4} component={LoadingOrderInput} source="loading_order" disabled={loadingOrderDisable}/>
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <Grid item xs={12} md={6} lg={4}> */}
				<FormGroupGrid xs={12} md={6} lg={4} title={translate("resources.orders.block-label.plumb-seal")}>
					<GridInput xs={6} sm={4} component={YesNoInput} source="plumb_seal" defaultValue={false} disabled />
					<GridInput xs={6} sm={8} component={TextInput} source="plumb_number" disabled />
				</FormGroupGrid>
			{/* </Grid> */}

			{/* <FormGroupGrid xs={12} md={6} lg={3} title={translate("resources.orders.block-label.storage-allocation")}>
					<GridInput xs={4} sm={6} component={SelectEnumInput} onChange={handleLabelSourceChange} options={{ format: "" }} source="label_source" key={"label_source"} enumName="label_source" enumObj={LabelSource}/>
					<GridInput xs={6} sm={8} component={NumberInputEx} options={{ format: "" }} source="label" />
			</FormGroupGrid> */}

			<FormGroupGrid>
				<GridInput xs={12} sm={6} md={3} hidden={values.service === OrderService.reloadCarCar} disabled>
					<ReferenceInput source="cooler_type_id" reference="cooler-types" filter={{ order_service: values.service }} disabled>
						<SelectUppercaseInput optionText="name" disabled />
					</ReferenceInput>
				</GridInput>
				<GridInput xs={6} sm={6} md={3} component={PrintedDocsInput} source="printed_doc" defaultValue={false} disabled />
				<GridInput xs={6} sm={6} md={3} component={CommodityInput} source="commodity" disabled />
				<GridInput xs={6} sm={6} md={3} component={YesNoInput} source="is_doc_for_nfsa" defaultValue={false}  disabled/>

			</FormGroupGrid>


			{/* <DimensionsBlock {...props} disabled /> */}
			<FormGroupGrid xs={12} lg={6} disabled>
				<GridInput xs={4} component={NumberInputEx} source="pallets" min={0} max={100} step={10} disabled />
				<GridInput xs={4} component={NumberInputEx} source="boxes" min={0} max={100} step={5} disabled />
				<GridInput xs={4} component={NumberInputEx} type="float" digitsCount={2} source="kilos" min={0} max={1000} step={100} disabled />
			</FormGroupGrid>
			
			<FormGroupGrid>
				<GridInput sm={12} md={6} component={TextInput} source="notes" multiline rows={6} validate={[maxLength(1024)]} />
				<GridInput sm={12} md={6} className="red-border" component={DangerTextInputForInternalNotes} source="internal_notes"
						   multiline rows={6} validate={[maxLength(500)]} />
			</FormGroupGrid>
		</GridForm>
	);
};
