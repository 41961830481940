import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';

const TerminalsContext = createContext();

const TerminalsProvider = ({ children }) => {
	const dataProvider = useDataProvider();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		dataProvider.getList("terminal", {
			pagination: {page: 0, perPage: -1},
			sort: {field: 'id', order: 'DESC'},
			filter: {},
		}).then((data) => {
			if (data?.data) {
				setData(data.data);
			};
		}).catch((error) => {
            console.error(error);
        }).finally(() => {
			setLoading(false);
		});
	}, [dataProvider]);

    const contextValue = { data, loading };

    return (
        <TerminalsContext.Provider value={contextValue}>
            {children}
        </TerminalsContext.Provider>
    );
};

const useTerminalsContext = () => {
    const context = useContext(TerminalsContext);
  
    if (!context) {
        throw new Error('useTerminals must be used within an TerminalsProvider');
    }
  
    return context;
};

export { TerminalsProvider, useTerminalsContext };
