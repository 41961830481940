import { da, de, enUS, es, fr, it, nl, nb, pl, ru } from "date-fns/locale";

// INFO: sequential is important!
const Languages = {
	en: "en",
	no: "no",
	de: "de",
	ru: "ru",
	pl: "pl",
	fr: "fr",
	es: "es",
	it: "it",
	da: "da",
	nl: "nl",
};

// INFO: sequential is important!
const locales = Object.keys(Languages);

const translateMatcher = {
	da: da,
	de: de,
	en: enUS,
	es: es,
	fr: fr,
	it: it,
	nl: nl,
	no: nb,
	pl: pl,
	ru: ru,
};

export { Languages, locales, translateMatcher };
