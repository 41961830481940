import React from "react";
import { Edit, SimpleForm, TabbedShowLayout, Tab, ReferenceManyField, Pagination } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';
import { CustomerCompanyEditForm } from './EditForm';
import { Notifications, CompanySettingsTab } from "../../../pages/settings/";
import { AppRoles, UserRole } from '../../../entities/';
import { MappersList } from "../CustomerCompaniesMappers/MappersList";
import { isInArray } from "../../../utils/general";

export const CompanyEdit = (props) => {
	const isAdminAppManager = props.permissions && isInArray(AppRoles, props.permissions.role);

	return (		
		isAdminAppManager ? 
		<TabbedShowLayout style={{paddingBottom: 8}} basePath={props.basePath}>
			<Tab label="tab.geneneral.name" >
				<Edit {...props} undoable={false}>
					<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
						<CustomerCompanyEditForm />
					</SimpleForm>
				</Edit>
			</Tab>
			<Tab label="tab.notifications.name">
				<Edit {...props} undoable={false} actions={null}>
					<Notifications {...props} />
				</Edit>
			</Tab>
			<Tab label="tab.settings.name">
				<Edit {...props} undoable={true} actions={null}>
					<CompanySettingsTab {...props}/>
				</Edit>
			</Tab>
			{isAdminAppManager && <Tab label="tab.mapper.name" path="mappers">
			<Edit {...props} undoable={true} actions={null}>
				<ReferenceManyField label={false} fullWidth reference="customer-company-mappers" filter={{"company_id": props.id}}
									pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 20, 25]} />} perPage={5}>
					<MappersList {...props}/>
				</ReferenceManyField>
				</Edit>
			</Tab>
			}
			
		</TabbedShowLayout> : 
		<Edit {...props} undoable={false}>
			<SimpleForm submitOnEnter={false} toolbar={<SaveWithCancelToolbar />}>
				<CustomerCompanyEditForm />
			</SimpleForm>
		</Edit>
	);
};
