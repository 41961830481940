import React, { useState } from 'react';

import { TextInput, maxLength, required, useNotify, useTranslate } from 'react-admin';

import { IconButton, Tooltip } from "@material-ui/core";
import NotesIcon from "@material-ui/icons/Notes";

import { GridForm, GridInput, FormGroupGrid } from '../../../components/GridForm';
import { SelectEnumInput } from '../../../components/inputs/SelectEnumInput';
import { DateTimeInput } from '../../../components/inputs';

import { EventsType } from '../../../entities/EventsType';
import { EventStatus } from '../../../entities/EventStatus';

import SyntaxDialog from './DescriptionSyntaxDialog';

export const EventForm = ({ isCreateForm = false, ...props }) => {
    const translate = useTranslate();
    const [isSyntaxDialogOpen, setIsSyntaxDialogOpen] = useState(false);

	let enabledFields;
	if ([EventStatus.CANCELED, EventStatus.COMPLETED].indexOf(props.record.status) !== -1) {
		enabledFields = {};
	}

    const SyntaxInfoButton = React.memo(() => (
        <Tooltip title={translate("osfc.dialogs.events.syntax_title")} placement="top">
            <IconButton size="small" onClick={() => setIsSyntaxDialogOpen(true)}>
                <NotesIcon />
            </IconButton>
        </Tooltip>
    ));

    return (
        <GridForm {...props} enabledFields={enabledFields}>
            <FormGroupGrid>
                <GridInput 
                    xs={12} 
                    sm={3} 
                    source='type' 
                    disabled={!isCreateForm}
                    validate={[required()]} 
                >
                    <SelectEnumInput
                        enumName='events_type' 
                        enumObj={EventsType} 
                    />
                </GridInput>


                <GridInput 
                    xs={12} 
                    sm={3} 
                    source='status' 
                    disabled
                    validate={[required()]} 
                >
                    <SelectEnumInput
                        enumName='events_status' 
                        enumObj={EventStatus} 
			            defaultValue={EventStatus.PLANNED}
                    />
                </GridInput>

                <GridInput 
                    xs={12} 
                    sm={6} 
                    component={TextInput} 
                    source='title' 
                    validate={[required(), maxLength(250)]} 
                    InputProps={{
                        endAdornment: <SyntaxInfoButton />
                    }}
                />
            </FormGroupGrid>

            <FormGroupGrid>
                <GridInput 
                    xs={12} 
                    sm={6} 
                    component={DateTimeInput} 
                    source='start_datetime' 
                    validate={[required()]} 
                    disabled={(
                        props.record.status && 
                        props.record.status !== EventStatus.PLANNED
                    )}
                    withLocalTimeZone
                />
                <GridInput 
                    xs={12} 
                    sm={6} 
                    component={DateTimeInput} 
                    source='end_datetime' 
                    validate={[required()]} 
                    withLocalTimeZone
                />
            </FormGroupGrid>

            <FormGroupGrid>
                <GridInput 
                    xs={12} 
                    component={TextInput} 
                    source='description' 
                    multiline 
                    rows={6} 
                    validate={[required(), maxLength(1024)]} 
                    InputProps={{
                        endAdornment: <SyntaxInfoButton />
                    }}
                />
                <SyntaxDialog
                    open={isSyntaxDialogOpen}
                    setOpen={setIsSyntaxDialogOpen}
                />
            </FormGroupGrid>
        </GridForm>
    );
};
