import React, { useMemo } from 'react';
import { useRecordContext } from 'react-admin';
import { useFormState } from 'react-final-form';

import { useTerminalsContext } from '../../contexts/TerminalsProvider';

const TerminalDatesInput = ({ 
    component: Component, 
    recordTerminalKey = "terminal_id", 
    terminalKey = "id", 
    priorityRecord = {},
    ...rest 
}) => {
    const contextRecord = useRecordContext(rest);
    const { values: formRecord } = useFormState();
    const { data: terminals, loading } = useTerminalsContext();

    const terminalTZ = useMemo(() => {
        if (terminals?.length) {
            const recordTerminalValue = (
                contextRecord[recordTerminalKey] ?? 
                formRecord[recordTerminalKey] ?? 
                priorityRecord[recordTerminalKey]
            );
            if (recordTerminalValue !== undefined) {
                const targetTerminal = terminals.find((item) => item[terminalKey] === recordTerminalValue);
                if (targetTerminal) {
                    return targetTerminal.time_zone;
                }
            }
        }
    }, [
        terminals, 
        contextRecord, 
        formRecord, 
        priorityRecord, 
        terminalKey, 
        recordTerminalKey, 
    ]);

    if (loading) return null;

    return (
        <Component 
            {...rest} 
            record={contextRecord} 
            timeZone={terminalTZ} 
        />
    );
};

export default TerminalDatesInput;
