import React from 'react';
import { Filter } from 'react-admin';

import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';


export const filterDefaultValues = {
	"terminal_id": [TERMINAL_ALL_VALUE],
};


export const ArticlesFilters = (props) => {
	return (
		<Filter {...props}>
			<ToggleTerminalsFilter source="terminal_id" alwaysOn key={"terminal_id"} exclude_all={true} />
		</Filter>
	);
};
