import React from 'react';

import { Filter, useTranslate } from 'react-admin';

import { ToggleTerminalsFilter } from '../../../components/filters/ToggleTerminalsFilter';
import { ToggleDatesFilter } from './ToggleDatesFilter';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	filtersBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	opat: {
		fontWeight: "bold",
	}
});


export const NeedOfWorkforceFilter = (props) => {
	const translate = useTranslate()
	const classes = useStyles();

	const opatInfo = React.useMemo(() => {
        return (props && props.data && Object.keys(props.data).length !== 0) ? 
				props.data[1]['total_revenue'] + ' / ' + props.data[1]['total_man_hours'] + ' = ' + props.data[1]['opat'] : '-'
    }, [props.data])

	return (
		<div className={classes.filtersBlock}>
			<Filter {...props}>
				<ToggleDatesFilter source="eta_date|etd_date" defaultValue={null} alwaysOn key={"eta_date|etd_date"}/>
				<ToggleTerminalsFilter source="terminal_id" exclude_all={true} defaultValue={null} alwaysOn key={"terminal_id"}/>
			</Filter>
			<div className={classes.opat}>
				OPAT ({translate("resources.labor-forecast.opat_info")}): {opatInfo}
			</div>
		</div>
	);
};
