import React, { useState, useEffect } from "react";
import { useInput, useLocale } from "react-admin";
import { addMonths, format, parseISO } from "date-fns";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import {translateMatcher} from "../../entities/Languages"


const useStyles = makeStyles((theme) => ({
	customDisabled: {
		"&.Mui-disabled": {
			color: theme.palette.secondary.main,
		},
	},
}));

const getFirstDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);
const getLastDayOfMonth = (date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getFilterDefault = (filterDefault) => {
	const completed_at_from = getFirstDayOfMonth(new Date());
	const completed_at_to = getLastDayOfMonth(new Date());
	return {
		completed_at_from: format(completed_at_from, "yyyy-MM-dd"),
		completed_at_to: format(completed_at_to, "yyyy-MM-dd"),
		...filterDefault,
	};
};

export const MonthRangeFilter = ({ fromSource, toSource, ...props }) => {
	const classes = useStyles();

	const { input: fromFilter } = useInput({ source: fromSource, ...props });
	const { input: toFilter } = useInput({ source: toSource, ...props });
	const locale = useLocale();

	const [selectedMonth, setSelectedMonth] = useState(
		fromFilter.value ? parseISO(fromFilter.value) : new Date()
	);

	useEffect(() => {
		const fromDate = getFirstDayOfMonth(selectedMonth);
		const toDate = getLastDayOfMonth(selectedMonth);
		fromFilter.onChange(format(fromDate, "yyyy-MM-dd"));
		toFilter.onChange(format(toDate, "yyyy-MM-dd"));
	}, [selectedMonth, fromFilter, toFilter]);

	const handlePrevMonth = () => setSelectedMonth(addMonths(selectedMonth, -1));
	const handleNextMonth = () => setSelectedMonth(addMonths(selectedMonth, 1));
	const formatMonthYear = (date) =>
		format(date, "LLL yyyy", { locale: translateMatcher[locale] });

	return (
		<Box>
			<ButtonGroup color="secondary" aria-label="Medium-sized button group">
				<Button onClick={handlePrevMonth}>
					{"< "}
					{formatMonthYear(addMonths(selectedMonth, -1))}
				</Button>
				<Button
					className={classes.customDisabled}
					style={{ width: "140px" }}
					component="span"
					disabled
				>
					{formatMonthYear(selectedMonth)}
				</Button>
				<Button onClick={handleNextMonth}>
					{formatMonthYear(addMonths(selectedMonth, 1))}
					{" >"}
				</Button>
			</ButtonGroup>
		</Box>
	);
};
