export const EventStatus = {
    PLANNED: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    CANCELED: 3,
};

export const EventStatusesColor = {
    [EventStatus.PLANNED]: "#FFFFFF",
    [EventStatus.IN_PROGRESS]: "#00AFF3",
    [EventStatus.COMPLETED]: "#FFE794",
    [EventStatus.CANCELED]: "#C65911",
};
