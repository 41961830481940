const baseStyles = {
    padding: '10px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'relative',
    overflow: 'hidden',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
};

const bannerStyles = {
    info: {
        backgroundColor: 'lightblue',
        color: 'black',
    },
    warning: {
        backgroundColor: 'yellow',
        color: 'red',
    },
    error: {
        backgroundColor: 'pink',
        color: 'darkred',
    },
};

const rootBannerName = 'banner';
const bannerStyleSheetName = `${rootBannerName}-styles`;
const targetSelectorBeforeInsert = 'main'; // tag <main>

const getBannerDivName = (identifier) => `${rootBannerName}-${identifier}`;

const mergeStyles = (...styles) => Object.assign({}, ...styles);

const doesContentFit = (element) => element.scrollWidth <= element.clientWidth;


const injectHtmlBanner = (type, message, customStyles = {}) => {
    const bannerId = getBannerDivName(type);
    let bannerDiv = document.getElementById(bannerId);

    if (!bannerDiv) {
        bannerDiv = document.createElement('div');
        bannerDiv.id = bannerId;
    }

    const existingMessages = bannerDiv.innerHTML;
    bannerDiv.innerHTML = existingMessages ? `${existingMessages} ${message}` : message;

    const styles = mergeStyles(baseStyles, bannerStyles[type] || customStyles || {});
    Object.assign(bannerDiv.style, styles);

    // Find the target element to insert before
    const targetElement = document.querySelector(targetSelectorBeforeInsert);
    if (targetElement) {
        targetElement.parentNode.insertBefore(bannerDiv, targetElement);
    } else {
        console.warn(`Target element with selector"${targetSelectorBeforeInsert}" not found.`);
    }

    if (!doesContentFit(bannerDiv)) {
        bannerDiv.style.whiteSpace = 'nowrap';
        bannerDiv.style.display = 'block';
        bannerDiv.style.overflow = 'hidden';
        const innerSpan = document.createElement('span');
        innerSpan.innerHTML = bannerDiv.innerHTML;
        Object.assign(innerSpan.style, {
            display: 'inline-block',
            paddingLeft: '100%',
            animation: 'float-message 10s linear infinite'
        });
        bannerDiv.innerHTML = '';
        bannerDiv.appendChild(innerSpan);
    } else {
        bannerDiv.style.whiteSpace = 'normal';
        bannerDiv.style.animation = '';
    }
};

const injectStylesSheet = () => {
    // Check if the stylesheet already exists
    const existingStyleSheet = document.querySelector(`style[data-name="${bannerStyleSheetName}"]`);

    if (!existingStyleSheet) {
        const styleSheet = document.createElement('style');
        styleSheet.setAttribute('data-name', bannerStyleSheetName); // Set a unique attribute to identify the stylesheet
        styleSheet.innerText = `
        @keyframes float-message {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
        }
        `;
        document.head.appendChild(styleSheet);
    }
};

const removeInjectedBanner = (type) => {
    const notificationId = getBannerDivName(type);
    const notificationDiv = document.getElementById(notificationId);
    
    if (notificationDiv) {
        notificationDiv.remove();  // Remove the banner element from the DOM
    }
};
  
const removeInjectedStyleSheet = () => {
    // Find the specific stylesheet by its unique data-name attribute
    const styleSheet = document.querySelector(`style[data-name="${bannerStyleSheetName}"]`);
  
    if (styleSheet) {
        styleSheet.remove();  // Remove the specific stylesheet
    }
};


export {
    injectHtmlBanner,
    injectStylesSheet,
    removeInjectedBanner,
    removeInjectedStyleSheet,
};


// Example usage
// injectHtmlBanner('info', 'This is an info alert.');
// injectHtmlBanner('warning', 'This is a warning alert.');
// injectHtmlBanner('error', 'This is an error alert.');
// injectHtmlBanner('maintenance', 'Maintenance scheduled for tomorrow from 1 AM to 3 AM.');
// injectHtmlBanner('custom', 'This is a custom alert with a very long message that needs to be checked if it fits into the block width. This is a custom alert with a very long message that needs to be checked if it fits into the block width.', {
//   backgroundColor: 'purple',
//   color: 'white',
// });
