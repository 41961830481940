import React, { useCallback, useEffect } from "react";
import { useListContext } from "react-admin";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";

import { useDraggableIDsContext } from "./DraggableIDsContext";
import { useDragsHistoryContext } from "./DragsHistoryContext";

export const reorderIds = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const DragAndDropWrapper = ({ children, onDragEnd, ...rest }) => {
	const { draggableIDs, setDraggableIDs } = useDraggableIDsContext();
	const { setHistoryChanges } = useDragsHistoryContext();


	const handleOnDragEnd = useCallback(
		(onDragProps) => {
			const { destination, source } = onDragProps;

			if (
				!destination ||
				(destination?.index === source?.index &&
					destination?.droppableId === source?.droppableId)
			) {
				return;
			}
			const newIDs = reorderIds(draggableIDs, source.index, destination.index);
			if (setDraggableIDs) setDraggableIDs(newIDs);
			if (setHistoryChanges)
				setHistoryChanges((historyChanges) => [
					...historyChanges,
					{ sourceID: source.index, destinationID: destination.index },
				]);
			onDragEnd && onDragEnd({ draggableIDs, ...onDragProps });
		},
		[onDragEnd, draggableIDs]
	);

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="droppable" type="ORDER">
				{(provided, snapshot) => (
					<>
						{React.cloneElement(children, {
							ref: provided.innerRef,
							...provided.droppableProps,
							...rest,
						})}
						{provided.placeholder}
					</>
				)}
			</Droppable>
		</DragDropContext>
	);
};
