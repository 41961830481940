import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { SaveWithCancelToolbar } from '../../../components/toolbars/SaveWithCancelToolbar';

import { EventForm } from '../forms/EventForm';


export const EventsEdit = (props) => (
    <Edit {...props} undoable={false}>
        <SimpleForm 
            submitOnEnter={false} 
            toolbar={<SaveWithCancelToolbar />}
        >
            <EventForm />
        </SimpleForm>
    </Edit>
);
