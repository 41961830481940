import React from 'react';

import { List, Datagrid, TextField, Pagination } from 'react-admin';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { DateTimeField } from '../../../components/fields/DateFormatField';

import { EventsFilter } from './ListFilters';
import { EventsType } from '../../../entities/EventsType';
import { EventStatus, EventStatusesColor } from '../../../entities/EventStatus';

const defaultSort = { field: 'id', order: 'DESC' };

const eventRowStyle = (record, index) => ({
    backgroundColor: EventStatusesColor[record.status],
});

const EventsPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

export const EventsList = (props) => {
    const classes = stylesForColorInheritInList();

    return (
        <List
            {...props}
            sort={defaultSort}
            perPage={25}
            pagination={<EventsPagination/>}
            exporter={false}
            bulkActionButtons={false}
            filters={<EventsFilter />}
            classes={classes}
        >
            <ScrollingWrapper>
                <Datagrid
                    rowClick='show'
                    rowStyle={eventRowStyle}
                >
                    <SelectEnumField 
                        source='type' 
                        enumName='events_type' 
                        enumObj={EventsType} 
                    />

                    <SelectEnumField 
                        source='status' 
                        enumName='events_status' 
                        enumObj={EventStatus} 
                    />

                    <DateTimeField source='start_datetime' withLocalTimeZone />
                    <DateTimeField source='end_datetime' withLocalTimeZone />
    
                    <TextField source='title' />
                </Datagrid>
            </ScrollingWrapper>
        </List>
    );
};
