import React from 'react';

import {
	usePermissions,
    NumberInput,
	BooleanInput,
	useTranslate
} from 'react-admin';

import Card from '@material-ui/core/Card';

import { UpdateForm } from './UpdateForm';
import { CardBlock } from './CardBlock';

import { GridInput, GridForm } from '../../components/GridForm';
import LanguageSelect from '../../components/inputs/LanguageSelect';
import { validateTimeRefreshOrderRange } from '../../utils/validation/user';
import { OrderPropsBlock } from './OrderPropsBlock';
import { UserRole } from '../../entities';
import { SysNotifType, SysNotifTypeTranslationChoices } from '../../entities/SysNotifTypes';
import BitwiseAutocompleteArrayInput from '../../components/inputs/BitwiseAutocompleteArrayInput';

export const UserSettingsBlock = (props) => {
	const { permissions } = usePermissions();

    if (!permissions) {
		return null;
	};

	let userNotifOptions = SysNotifTypeTranslationChoices;
	if (permissions.role !== UserRole.customer_and_transporter_driver) {
		userNotifOptions = SysNotifTypeTranslationChoices.filter((option) => option.id !== SysNotifType.mobile_push);
	}
	return (
		<UpdateForm
			id={permissions.id}
			resource="settings"
			render={({ body, buttons }) => (
				<CardBlock Component={Card} 
						   buttons={buttons} children={body} />
			)}
			permissions={permissions}
		>
            <GridForm {...props}>
                <GridInput md={3} sm={6} component={LanguageSelect} source="locale"/> 
                <GridInput md={3} sm={6}>
                    <NumberInput source="time_refresh_order" step={0.5} min={0} max={10}
                        format={value => value / 60}
                        parse={value => value * 60} validate={validateTimeRefreshOrderRange}/>
                </GridInput> 
				<GridInput md={3} sm={6} component={BooleanInput} source="is_menu_collapse"/> 
            </GridForm>
			<GridForm {...props}>
				<GridInput md={3} sm={6} component={BitwiseAutocompleteArrayInput} source="order_props_changes_notify_type"
					label="pages.settings.fields.order_props_changes_notify_type" choices={userNotifOptions} fullWidth />
				<GridInput md={3} sm={6} component={OrderPropsBlock} label="pages.settings.fields.order_props_changes" />
			</GridForm>
		</UpdateForm> 
	);
}
