import React from 'react';
import { Filter, ReferenceInput, useTranslate } from 'react-admin';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { SelectUppercaseInput } from '../../../components/inputs/SelectUppercaseInput';
import { DateInput } from '../../../components/inputs/DateTimeInput';
import { ToggleTerminalsFilter, TERMINAL_ALL_VALUE } from '../../../components/filters/ToggleTerminalsFilter';
import { ToggleDatesFilter, ToggleDatesEnum } from '../../../components/filters/ToggleDatesFilter';
import { ToggleStateFilter } from '../../../components/filters/ToggleStateFilter';
import { ToggleTitleInput } from '../../../components/filters/ToggleInputs';

import TextInputEx from "../../../components/_extensions/react-admin/input/TextInputEx";
import { filterColors } from '../../../utils/constants';

import { ServiceInput } from '../forms/inputs/ServiceInput';


export const filterDefaultValues = {
	"eta_date|etd_date": [ToggleDatesEnum.TODAY],
	"terminal_id": [TERMINAL_ALL_VALUE],
};


const useStyles = makeStyles(t => ({	
	filterSearch: {
		backgroundColor: filterColors.search
	},
}));


export const TasksFilter = ({isSearchInDocs, ...props}) => {
	const translate = useTranslate();
	const classes = useStyles();
	return(
	<Filter {...props}>

	{!isSearchInDocs && [
		<ToggleDatesFilter 
			source="eta_date|etd_date" 
			defaultValue={null} 
			alwaysOn 
			key={"eta_date|etd_date"}
		/>,

		<ToggleTerminalsFilter 
			source="terminal_id" 
			defaultValue={null} 
			alwaysOn 
			key={"terminal_id"}
		/>,

		<ToggleStateFilter 
			source="state" 
			defaultValue={undefined} 
			alwaysOn 
			key={"state"}
		/>,

		<TextInputEx 
			source="id" 
			key="id" 
		/>,

		<TextInputEx 
			source="rank" 
			key="rank" 
		/>,

		<TextInputEx 
			source="estimate_duration" 
			key="estimate_duration" 
		/>,

		<ServiceInput 
			source="service" 
			resettable 
			key={"service"}
		/>,

		<ReferenceInput 
			source="status" 
			reference="task-statuses" 
			resettable 
			key="status"
		>,
			<SelectUppercaseInput optionText="name"/>	
		</ReferenceInput>,

		<DateInput 
			source="deadline_date" 
			margin="dense" 
			resettable 
			key="deadline_date"
		/>,

		<DateInput 
			source="deadline_time" 
			margin="dense" 
			resettable 
			key="deadline_time"
		/>,

		<DateInput 
			source="eta_date" 
			margin="dense" 
			resettable 
			key="eta_date"
		/>,

		<DateInput 
			source="etd_date" 
			margin="dense" 
			resettable 
			key="etd_date"
		/>]}

		<ToggleTitleInput source="is_search_in_docs" value={true} key={"is_search_in_docs"} 
		label0={translate("osfc.filters.toggles.search_in_docs")} label1={translate("osfc.filters.toggles.back_to_tasks")} 
		alwaysOn className={classes.filterSearch}/>
		
		{isSearchInDocs && <TextInputEx label="Search in docs" source="doc_search_text" alwaysOn/>}
	</Filter>
)};
