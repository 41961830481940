import React from 'react';
import { Link, Route } from 'react-router-dom';

import {
	TopToolbar,
	List,
	Datagrid,
	TextField,
	FunctionField,
	ReferenceManyField,
	DeleteButton,
	ResourceContextProvider,
	Pagination,
} from 'react-admin';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';

import { ScrollingWrapperInCard } from '../../../components/ScrollingWrapper';
import { DateTimeField } from '../../../components/fields/DateFormatField';
import { CopyField } from '../../../components/fields/CopyField';

import { CreateAPIKey } from './Create';
import { EditAPIKey } from './Edit';
import DeleteRestoreButtons from "../../../components/toolbars/DeleteRestoreButtons";

const EditButton = (props) => {
	return (
		<IconButton
			component={Link}
			to={{ pathname: `/settings/api-keys/${props.record.id}` }}
			size="small"
			color="default"
		>
			<EditIcon fontSize="inherit" />
		</IconButton>
	);
};

const CreateButton = (props) => (
	<Button
		component={Link}
		to={{ pathname: `/settings/api-keys/create` }}
		size="medium"
		color="primary"
		startIcon={<AddIcon />}
	>
		CREATE
	</Button>
);

const defaultSort = { field: 'id', order: 'DESC' };

export const APIKeysDatagrid = (props) => {
	return (
		<Paper variant="outlined">
			<Box display="flex" justifyContent="right" padding={1}>
				<CreateButton />
			</Box>
			<ScrollingWrapperInCard>
				<Datagrid {...props}>
					<TextField source="name" label="Name" />
					<DateTimeField source="created_at" label="Created at" withLocalTimeZone />
					<DateTimeField source="expires_at" label="Expires at" withLocalTimeZone />
					<CopyField
						sortable={false}
						source="token"
						label="Token"
						style={{
							width: '300px',
							// minWidth: "100px",
							// maxWidth: "300px",
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: 'inline-block',
						}}
					/>
					<EditButton label={'Edit'} />
					<DeleteRestoreButtons
						resource="api-keys"
						requestMethod={"delete"}
					/>
				</Datagrid>
			</ScrollingWrapperInCard>
		</Paper>
	);
};

export const APIKeysBlock = (props) => {
	return (
		<ReferenceManyField
			target="id"
			reference="api-keys"
			sort={defaultSort}
			perPage={25}
			pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
			{...props}
		>
			{/* <ResourceContextProvider value="api-keys"> */}
			<APIKeysDatagrid
			// reference={'api-keys'}
			// sort={{ field: 'id', order: 'ASC' }}
			// perPage={30}
			// {...props}
			/>

			{/* </ResourceContextProvider> */}
		</ReferenceManyField>
	);
};

APIKeysBlock.defaultProps = {
	addLabel: true,
	className: 'ra-field-datagrid',
};

export const apiKeysRouts = [
	<Route exact path="/settings/api-keys/create" component={CreateAPIKey} />,
	<Route exact path="/settings/api-keys/:id" component={EditAPIKey} />,
];
