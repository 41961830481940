import React from 'react';
import { Layout, useDataProvider, usePermissions } from 'react-admin';
import { useDispatch } from 'react-redux';

import { SocketProvider } from '../contexts/SocketProvider';
import EventBannerProvider from '../contexts/EventBannerProvider';
import { TerminalsProvider } from '../contexts/TerminalsProvider';

import { MyAppBar } from './AppBar';


export const MyLayout = props => {
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (permissions && permissions.id) {
            dataProvider.getOne("settings", {
                id: permissions.id,
            }).then((data) => {
                if (data?.data) {
                    dispatch({ type: 'SET_SIDEBAR_OPEN', payload: !data.data.is_menu_collapse });
                }
            });
        }
    }, [permissions])

    return (<Layout {...props} appBar={MyAppBar} />)
};


export default (props) => (
    <SocketProvider>
        <EventBannerProvider>
            <TerminalsProvider>
                <MyLayout {...props} />
            </TerminalsProvider>
        </EventBannerProvider>
    </SocketProvider>
);
