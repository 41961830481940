import React from "react";

import { maxLength, ReferenceInput, SelectInput, TextInput, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';

import { FormGroupGrid, GridForm, GridInput } from '../../../components/GridForm';

import { DateInput, TimeInput } from '../../../components/inputs';
import { YesNoInput } from '../../../components/inputs/YesNoInput';
import { OSFCUserSelect } from '../../../components/inputs/OSFCUserSelect';

import { required } from '../../../utils/validation/required';
import TerminalDatesInput from "../../../components/inputs/TerminalDatesInput";


const EntityForm = (props) => {
    const form = useForm();

    const handle_eta_change = (event) => {
        if (event) {
            const eventDate = new Date(event);
            const year = eventDate.getFullYear();
            const month = String(eventDate.getMonth() + 1).padStart(2, '0');
            const day = String(eventDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            form.change("etd_date", formattedDate)
        }
    };

    const handle_etd_change = (event) => {
        if (event) {
            const eventDate = new Date(event);
            const year = eventDate.getFullYear();
            const month = String(eventDate.getMonth() + 1).padStart(2, '0');
            const day = String(eventDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            form.change("eta_date", formattedDate)
        }
    };

    return (
        <GridForm {...props}>
            <FormGroupGrid>
                <GridInput 
                    xs={12} sm={6}
                    component={YesNoInput} 
                    source="priority" 
                    defaultValue={false} 
                    disabled
                />

                <GridInput 
                    xs={12} sm={6} 
                    validate={[required()]}
                    disabled
                >
                    <ReferenceInput 
                        source="terminal_id" 
                        reference="terminal" 
                        validate={[required()]} 
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </GridInput>
            </FormGroupGrid>

            <FormGroupGrid 
                xs={12}
                style={{ marginBottom: 20 }}
            >
                
                <GridInput xs={6} sm={3}>
                    <TerminalDatesInput 
                        component={DateInput} 
                        source='eta_date'
                        onChange={handle_eta_change} 
                        validate={[required()]} 
                        disabled 
                    />
                </GridInput>

                <GridInput xs={6} sm={3}>
                    <TerminalDatesInput 
                        component={TimeInput} 
                        source='eta_time'
                        disabled 
                    />
                </GridInput>

                <GridInput xs={6} sm={3}>
                    <TerminalDatesInput 
                        component={DateInput} 
                        source='etd_date'
                        onChange={handle_etd_change} 
                        validate={[required()]} 
                        disabled 
                    />
                </GridInput>

                <GridInput xs={6} sm={3}>
                    <TerminalDatesInput 
                        component={TimeInput} 
                        source='etd_time'
                        disabled 
                    />
                </GridInput>
            </FormGroupGrid>

            <FormGroupGrid xs={12}>
                <GridInput 
                    source="assignee_1" 
                    sm={6} md={6} lg={6} 
                    component={OSFCUserSelect} 
                    disabled
                />

                <GridInput 
                    source="assignee_2" 
                    sm={6} md={6} lg={6} 
                    component={OSFCUserSelect} 
                    disabled
                />

                <GridInput 
                    sm={12} md={12} 
                    component={TextInput} 
                    source="notes" 
                    multiline 
                    rows={6} 
                    validate={[maxLength(1024)]} 
                    disabled
                />
            </FormGroupGrid>
        </GridForm>
    );
};


export default EntityForm;
