import React from 'react';


export const useClock = (generator, props) => {
    const [time, setTime] = React.useState(generator(...props));

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(generator(...props));
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return time;
};
