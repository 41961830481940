import React, { useEffect } from 'react';

import {
	TextField, Pagination, List, 
	SelectInput, Loading, FunctionField, 
	useTranslate, useRefresh, useGetList, 
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import { ScrollingWrapper } from '../../../components/ScrollingWrapper';
import { DateFormatField } from '../../../components/fields/DateFormatField';
import { LongTextField } from '../../../components/fields/LongTextField';
import { PriorityField } from '../../../components/fields/PriorityField';
import { ReferenceEditableField } from '../../../components/fields/EditableField';
import { Datagrid } from '../../../components/CustomizableDataGrid/';
import { SearchResultDocumentsRowExpand } from '../../../components/SearchResultRowExpand';
import { ObjectChipField } from '../../../components/fields/ObjectChipField';
import { useAsyncColumnsStorage } from '../../../utils/hooks/useAsyncColumnsStorage';
import { useIsSearchInDocs } from '../../../utils/hooks/useIsSearchInDocs';
import { useExpandDefaultForAll } from "../../../utils/hooks/useExpandDefaultForAll";
import { TextEditableField } from '../../../components/fields/EditableField';

import { AppManagerRoles, UserRole } from '../../../entities/';
import { TaskServices } from '../../../entities/TaskService';

import { TasksActions, ActionButtonsField } from './Actions';
import { TasksFilter, filterDefaultValues } from './Filters';
import { isInArray } from '../../../utils/general';
import GridUserDataProvider, { useGridUserDataContext } from '../../../contexts/GridUserDataProvider';


const defaultSort = { field: 'id', order: 'DESC' };

const BG_COLOR_WHITE = "#ffffff";
const BG_COLOR_GREEN = "#b7ff7d";
const BG_COLOR_RED = "#ff8c8c";

const useStyles = makeStyles({
	list: {
		noResults: {
			backgroundColor: 'red',
			padding: 100,
		},
		actions: {
			backgroundColor: 'blue',
		},
	},
});

const useCellStyles = makeStyles({
	cellGreen: {
		backgroundColor: BG_COLOR_GREEN, // green
	},
	cellRed: {
		backgroundColor: BG_COLOR_RED, // red
	},
	noPaddingButton: {
		padding: '0',

		'&>span>button': {
			backgroundColor: BG_COLOR_WHITE,
			border: "solid 1px white",
			minWidth: 'auto'
		},
	},
	noPaddingButtonStart: {
		'&>span>button': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
			borderRight: "none"
		},
		// '.order-cc-late &>span>button': {
		// 	backgroundColor: BG_COLOR_RED
		// },
	},
	noPaddingButtonEnd: {
		'&>span>button': {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			borderLeft: "none"
		},
		// '.order-cc-late &>span>button': {
		// 	backgroundColor: BG_COLOR_GREEN
		// },
	},
	// cellEtaTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_RED,
	// 		color: BG_COLOR_GREEN,
	// 	},
	// },
	// cellEtdTime: {
	// 	'.order-cc-late &>span': {
	// 		backgroundColor: BG_COLOR_GREEN,
	// 		color: BG_COLOR_RED
	// 	},
	// },
});

const Label = ({title, label}) => {
	return (
		<Tooltip title={title} placement="bottom">
			<div>
				{label}
			</div>
		</Tooltip>
	);
};

const TaskPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;


export const _TasksList = ({permissions, ...props}) => {
	const { filters: currentFilters, sort: currentSort } = useGridUserDataContext();
	const classes = useStyles();
	const cellClasses = useCellStyles();
	const translate = useTranslate();
	const t = (field) => translate(`resources.tasks.fields.${field}`);
	const isSearchInDocs = useIsSearchInDocs(currentFilters, props.resource);
	const columnsAsyncStorage = useAsyncColumnsStorage("tasks-columns");
	const refresh = useRefresh();

	const { ids: _, data: statuses } = useGetList("task-statuses");

	useEffect(() => {
		if (permissions?.time_refresh_order) {
			const intervalId = setInterval(refresh, permissions.time_refresh_order * 1000); 
			return () => clearInterval(intervalId);
		}
	}, [permissions, refresh]);

	useExpandDefaultForAll(props.resource);

	if (!permissions || currentFilters === undefined || columnsAsyncStorage.columns === undefined) {
		return <Loading loadingSecondary=""/>;
	}

	const orderRowStyle = (record, index) => {
		return {
			backgroundColor: statuses[record.status] && statuses[record.status].color,
		};
	};

	const isAppManager = permissions && isInArray(AppManagerRoles, permissions.role);

	return (
		<List
			{...props}
			perPage={50}
			empty={false}
			pagination={<TaskPagination />}
			classes={classes.list}
			exporter={false}
			filters={<TasksFilter isSearchInDocs={isSearchInDocs}/>}
			filterDefaultValues={currentFilters}
			bulkActionButtons={false}
			sort={currentSort}
			actions={<TasksActions />}
		>
			<ScrollingWrapper> 
				<Datagrid 
					rowStyle={orderRowStyle}
					storage={columnsAsyncStorage}
					isRowExpandable={() => isSearchInDocs}
					expand={isSearchInDocs ? <SearchResultDocumentsRowExpand /> : false}
					expandIconCell={{padding: "2px"}}
				>
					<ActionButtonsField
						isSearchInDocs={isSearchInDocs} 
					/>

					<PriorityField 
						source="priority" 
						label={<Label title={t('priority')} label="P"/>} 
						sortable={false}
					/>

					<ReferenceEditableField
						optimized={true}
						editable={isAppManager}
						permissions={permissions}
						source="status"
						reference="task-statuses"
						additionalParams={['set_order_completed']}
						editField={<SelectInput optionText="name" />}								
					>
						<TextField source="status_name"
						/>
					</ReferenceEditableField>

					<ObjectChipField 
						source="service" 
						object={TaskServices} 
						titleKey="short_name" 
						tooltipKey="full_name"
					/>

					<TextField 
						source="title" 
					/>

					<TextEditableField 
						editable={isAppManager} 
						source="rank"
					/>

					<TextField 
						source="rank_auto" 
					/>

					<TextField 
						source="terminal_name" 
						label="resources.tasks.fields.terminal" 
					/>

					<DateFormatField 
						source="deadline_date" 
					/>

					<TextField 
						source="deadline_time" 
					/>

					<TextField 
						source="et_date_union" 
					/>
					
					<DateFormatField 
						source="eta_date" 
						headerClassName={cellClasses.cellGreen} 
					/>

					<TextField 
						source="eta_time" 
						headerClassName={cellClasses.cellGreen} 
						cellClassName={cellClasses.cellEtaTime} 
					/>

					<ReferenceEditableField 
						source="assignee_1" 
						reference="osfc-users"
						optimized={true}
						headerClassName={cellClasses.cellGreen}
						permissions={permissions}
						editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
						filter={{ no_range: true, is_terminal: true, ...props.filter }}
						allowEmpty={true}
						format={value => value === 0 ? '' : value} 
						parse={value => value === '' ? 0 : value}
						>
						<TextField source="assignee_1_name" />
					</ReferenceEditableField>

					<DateFormatField 
						source="etd_date" 
						headerClassName={cellClasses.cellRed} 
					/>

					<TextField 
						source="etd_time" 
						headerClassName={cellClasses.cellRed} 
						cellClassName={cellClasses.cellEtdTime} 
					/>

					<ReferenceEditableField 
						source="assignee_2" 
						reference="osfc-users"
						optimized={true}
						headerClassName={cellClasses.cellRed}
						permissions={permissions}
						editField={<SelectInput optionText={rec => rec.first_name + ' ' + rec.last_name} emptyValue="" />}
						filter={{ no_range: true, is_terminal: true, ...props.filter }}
						allowEmpty={true}
						format={value => value === 0 ? '' : value} 
						parse={value => value === '' ? 0 : value}
						>
						<TextField source="assignee_2_name" />
					</ReferenceEditableField>

					<LongTextField 
						source="notes" 
						sortable={false}
					/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};

export const TasksList = (props) => (
	<GridUserDataProvider 
		resource={props.resource}
		filterDefaultValues={filterDefaultValues} 
		sortDefaultValues={defaultSort}
	>
		<_TasksList {...props} />
	</GridUserDataProvider>
);
