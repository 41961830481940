import React from "react";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { ordersResource } from "../../resources/orders/";
import { ordersByAPIKeysResource } from "../../resources/orders-by-api-keys/";
import { pricelistArticlesResource } from "../../resources/pricelist-articles/";
import { pricelistHolidaysResource } from "../../resources/pricelist-holidays/";
import { osfcManagersResource } from "../../resources/osfc-users";
import { customerCompaniesResource } from "../../resources/customer-companies/";
import { customerUsers } from "../../resources/customer-users";
// import { transportersResource } from '../../resources/transporters';
import { customerDriversResource } from "../../resources/drivers";
import { translationsResource } from "../../resources/translations";
import { hotlinksResource } from "../../resources/hotlinks";
import { transportTrucksResource } from "../../resources/trucks";
import { transportTrailersResource } from "../../resources/trailers";
import { terminalsResource } from "../../resources/terminal";
import { adminLogsResource } from "../../resources/admin-log";
import { adminActionsResource } from "../../resources/admin-actions";
import { backupLogPricelistResource } from "../../resources/backup-log-pricelist";
import { adminInvoicesResource } from "../../resources/invoices";
import { eventsResource } from "../../resources/events";
import { recurringTaskResource } from '../../resources/recurring_tasks';
import { trucksTrafficResource } from "../../resources/trucks-traffic";
import { tasksResource } from "../../resources/tasks/";
import { entitiesResource } from "../../resources/entities/";
import { areasResource, portsResource } from "../../resources/ports-areas";
import AdminOrdersExportSearch from "../../pages/admin-order-export-search";
import { notifLogsResource } from "../../resources/notif-logs";
import { laborForecastResource } from "../../resources/labor-forecast";
import { articleTemplatesResource } from "../../resources/pricelist-article-templates";
import { OrdersMultipleChangesPage } from "../../resources/orders/MultipleChanges2";
import Messages from "../../resources/messages/List/List";
import { financialStatementsResource } from "../../resources/financial-statements";
import { apiKeysRouts } from "../../pages/settings/APIKeys";
import { userManualResource } from "../../resources/user-manual";
import { entityDocumentStatusResource } from '../../resources/entity-document-status';

export const adminResources = [
	<Resource name="orders" {...ordersResource} />,
	<Resource name="orders-by-api-keys" {...ordersByAPIKeysResource} />,
	<Resource {...osfcManagersResource} name="osfc-users" />,
	<Resource {...customerCompaniesResource} name="customer-companies" />,
	<Resource {...customerUsers} name="customer-users" />,
	// <Resource {...transportersResource} name="transporters" />,
	<Resource name="transporters" intent="registration" />,
	<Resource {...customerDriversResource} create={null} edit={null} name="drivers" />,
	<Resource {...transportTrucksResource} name="trucks" />,
	<Resource {...transportTrailersResource} name="trailers" />,
	<Resource {...terminalsResource} name="terminal" />,
	<Resource
		{...pricelistArticlesResource}
		name="pricelist-articles"
		options={{ hasUpload: true }}
	/>,
	<Resource {...pricelistHolidaysResource} name="pricelist-holidays" />,
	<Resource {...portsResource} name="ports" intent="registration" />,
	<Resource {...areasResource} name="areas" intent="registration" />,
	<Resource
		{...articleTemplatesResource}
		name="pricelist-article-templates"
		intent="registration"
	/>,
	<Resource name="notif-logs" {...notifLogsResource} />,
	<Resource name="trucks-traffic" {...trucksTrafficResource} />,
	<Resource name="labor-forecast" {...laborForecastResource} />,
	<Resource name="recurring-tasks" {...recurringTaskResource} />,
	<Resource {...translationsResource} name="translations" />,
	<Resource {...hotlinksResource} name="hotlinks" />,
	<Resource {...adminLogsResource} name="admin-logs" />,
	<Resource {...adminActionsResource} name="admin-actions" />,
	<Resource {...adminInvoicesResource} name="invoices" />,

	<Resource {...backupLogPricelistResource} name="backup-log-pricelist" />,
	<Resource {...eventsResource} name="events" />,

	<Resource name="tasks" {...tasksResource} />,
	<Resource name="entities" {...entitiesResource} />,
	<Resource name="financial-statements" {...financialStatementsResource} />,
	<Resource name="user-manuals" {...userManualResource} />,
	<Resource name="order-history" intent="registration" />,
	<Resource name="task-history" intent="registration" />,
	<Resource name="entity-history" intent="registration" />,
	<Resource name="task-statuses" intent="registration" />,

	<Resource name="backup-pricelist-articles-with-special" intent="registration" />,
	<Resource name="pricelist-special-price" intent="registration" />,
	<Resource name="customer-employees" intent="registration" />,

	<Resource name="users" intent="registration" />,
	<Resource name="companies" intent="registration" />,
	<Resource name="order-statuses" intent="registration" />,
	<Resource name="outgoing-doc-statuses" intent="registration" />,
	<Resource name="company-notifications" intent="registration" />,
	<Resource name="api-keys" intent="registration" />,
	<Resource name="customer-company-mappers" intent="registration" />,
	<Resource name="time-zones" intent="registration" />,
	<Resource name="cooler-types" intent="registration" />,
	<Resource name="order-pricelist-history" intent="registration" />,
	<Resource
		name="order-pricelist"
		intent="registration"
		options={{ hasAdd: true, hasEdit: true }}
	/>,
	<Resource name="rank-priorities" intent="registration" />,
	<Resource name="entity-document-status" {...entityDocumentStatusResource} />,
];

export const adminRoutes = [
	...apiKeysRouts,
	<Route exact path="/messages" component={Messages} />,
	<Route exact path="/admin-orders-export-search" component={AdminOrdersExportSearch} />,
	<Route exact path="/orders/multiple-changes" component={OrdersMultipleChangesPage} />,
];
