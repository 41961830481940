import React from 'react';

import { List } from 'react-admin';

import { stylesForColorInheritInList } from '../../../components/_helpers/useStyles';
import { NeedOfWorkforceFilter } from './ListFilters';
import { WorkforceGrid } from './WorkforceGrid';
import { ToggleDatesLaborForecastEnum } from './ToggleDatesFilter';
import { Terminal } from '../../../entities/Terminal';


const defaultFilters = {
	"eta_date|etd_date":[ToggleDatesLaborForecastEnum.TODAY],
	"terminal_id": [Terminal.WSC]
}

export const NeedOfWorkforceList = ({permissions, ...props}) => {
	const classes = stylesForColorInheritInList(); 

	return (
		<List
			{...props}
			exporter={false}
			bulkActionButtons={false}
			filters={<NeedOfWorkforceFilter {...props}/>}
			actions={null}
			classes={classes}
			pagination={false}
			filterDefaultValues={defaultFilters}
		>
			 <WorkforceGrid {...props}/>	
		</List>
	);
};
