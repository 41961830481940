import React from 'react';
import { FunctionField, useTranslate } from 'react-admin';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { decodeOptions } from '../inputs/BitwiseAutocompleteArrayInput';

const useStyles = makeStyles({
    chip: {
        fontSize: 12, 
        margin: '2px', 
    },
    chipContainer: {
        display: 'flex', 
        flexWrap: 'wrap', 
        gap: '4px', 
    }
});

const BitwiseArrayField = ({ 
    options, 
    withTooltip = true, 
    optionText = 'name', 
    optionValue = 'id', 
    ...props 
}) => {
    const classes = useStyles();
    const t = useTranslate();

    const valueGetter = (option) => option[optionValue];
    const textGetter = (option) => t(option[optionText]);

    return (
        <FunctionField
            {...props}
            addLabel={true}
            render={record => {
                const value = record[props.source];
                if (value == null) return null;

                let decodedArray = decodeOptions(value, options, valueGetter);
                if (decodedArray.reduce((a, b) => a + b, 0) !== 0) {
                    decodedArray = decodedArray.filter((number) => number !== 0);
                }
                const filtredOptions = options.filter((option) => decodedArray.includes(valueGetter(option)));

                return (
                    <div className={classes.chipContainer}>
                        {filtredOptions.map((option, index) => (
                            withTooltip ? 
                                <Chip 
                                    key={index}
                                    fontSize="small" 
                                    variant="outlined" 
                                    className={classes.chip} 
                                    size="small" 
                                    label={textGetter(option)} 
                                />
                            : index === filtredOptions.length - 1 ? textGetter(option) : `${textGetter(option)}; `
                        ))}
                    </div>
                );
            }}
        />
    );
};

BitwiseArrayField.defaultProps = {
    addLabel: true,
};

export default BitwiseArrayField;
