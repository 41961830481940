import React from "react";
import {
	List,
	Datagrid,
	Pagination,
	Loading,
	FunctionField,
	useTranslate,
	usePermissions,
	TextField,
	ReferenceField,
} from "react-admin";
import { ScrollingWrapper } from "../../../components/ScrollingWrapper";
import { DateFormatField } from "../../../components/fields/DateFormatField";
import { ModalDialog } from "../../../components/ModalDialog";
import { ArrayChipField } from "../../../components/fields/ArrayChipField";
import { UserRole } from "../../../entities/UserRole";

import { ListFilter } from "./ListFilters";
import { getFilterDefault } from "../../../components/filters/MonthRangeFilter";
const defaultSort = { field: "completed_at", order: "DESC" };

const OrdersArrayField = (props) => {
	const translate = useTranslate();
	return (
		<FunctionField
			{...props}
			sortable={false}
			render={(record) => {
				return (
					<ModalDialog
						title={
							<span>
								{translate(
									"resources.orders-by-api-keys.fields.order_ids.dialog-title"
								)}{" "}
								{record.completed_at}:
							</span>
						}
						content={<ArrayChipField resource={"orders"} ids={record["order_ids"]} />}
					/>
				);
			}}
		/>
	);
};

export const OrdersByAPIKeysList = (props) => {
	const { permissions } = usePermissions();
	if (!props.permissions) {
		return <Loading loadingSecondary="" />;
	}
	const isAdmin = permissions?.role === UserRole.admin;

	return (
		<List
			{...props}
			exporter={false}
			empty={false}
			bulkActionButtons={false}
			sort={defaultSort}
			perPage={25}
			pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
			filters={<ListFilter />}
			filterDefaultValues={getFilterDefault()}
		>
			<ScrollingWrapper>
				<Datagrid rowClick={null} size="small">
					<DateFormatField source="completed_at" withLocalTimeZone />
					{isAdmin && (
						<ReferenceField
							sortable={false}
							source="company_id"
							reference={"companies"}
							// reference={"customer-companies"}
							// link={true}
						>
							<TextField source="full_name" />
						</ReferenceField>
					)}
					<OrdersArrayField
						sortable={false}
						source={"order_ids"}
						label={"resources.orders-by-api-keys.fields.order_ids.name"}
						{...props}
					/>
				</Datagrid>
			</ScrollingWrapper>
		</List>
	);
};
