import React from "react";
import { IsDraggableProvider } from "./IsDraggableContext";
import { DraggableIDsProvider } from "./DraggableIDsContext";
import { DragsHistoryProvider } from "./DragsHistoryContext";

export const DraggableContextsWrapper = ({ isDraggableDefault=false, children, ...rest }) => (
	<IsDraggableProvider isDraggableDefault={isDraggableDefault}>
		<DraggableIDsProvider>
            <DragsHistoryProvider>
                {React.cloneElement(children, { ...rest })}
            </DragsHistoryProvider>
        </DraggableIDsProvider>
	</IsDraggableProvider>
);
