import React, { useMemo, useState } from 'react';
import {TextField, ReferenceField, NumberField, RichTextField, Tab, SelectInput, FunctionField, 
		useRecordContext, } from 'react-admin';

import Box from '@material-ui/core/Box'

import {YesNoField} from '../../../components/fields/YesNoField';
import {DateFormatField} from '../../../components/fields/DateFormatField';
import TransDriverField from '../../../components/fields/TransDriverField';
import {SelectTextField} from '../../../components/fields/SelectTextField';

import {FlexLayout, FlexRow} from '../../../components/FlexShowLayout';

import {PricelistField} from './Pricelist/';

import {PriorityField} from '../../../components/fields/PriorityField';
import {TextEditableField, ReferenceEditableField} from '../../../components/fields/EditableField';
import {DocumentsFieldBlock} from "../../../components/fields/DocumentsField";
import { LongTextField } from '../../../components/fields/LongTextField';

import {UserRole} from '../../../entities/UserRole';
import {OrderService, OrderServiceNames} from '../../../entities/OrderService';

import ChatContainer from "../Chat";
import {HistoryField, OrderPricelistHistoryField} from "./History";
import {OrderHistoryGrid} from "./History/OrderHistoryGrid";
import {OrderPricelistHistoryGrid} from "./History/OrderPricelistHistoryGrid";
import { GenerateDocumentsOutputButton } from '../../../components/fields/DocumentsField/GenerateDocumentsOutputButton';

import { UserRoleByCompanyType, OrderDocumentOutputType } from '../../../entities';

import { LabelSourceField } from '../../../components/fields/LabelSourceField';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';

const formatDriverLabel = (driverData) => {
	const driverFirstName = driverData?.["first_name"] ?? "";
	const driverLastName = driverData?.["last_name"] ?? "";

	let label = '  ';
	if (driverFirstName || driverLastName) {
		const _label = `${driverFirstName} ${driverLastName}`.trim();
		if (_label.length) {
			label = _label;
		}
	}

	return label;
};

const TransDriverFieldWrapper = (props) => {
	const [driverData, setDriverData] = useState({});

	const handleNestedResourceData = (_driverData) => {
		setDriverData(_driverData.data);
	}

	const label = useMemo(() => formatDriverLabel(driverData), 
		[driverData?.["first_name"], driverData?.["last_name"]]);

	return (
		<TransDriverField 
			{...props} 
			refSource={`${props.prefix}_driver_id`} 
			label={label}
			handleNestedResourceData={handleNestedResourceData}
		/>
	);
};


export const PlugTab = () => <div/>;

export const GeneralTab = ({isOSFCManager, isCustomerAsTrans, isPricelistNeed=true, ...props}) => {	
	// if (isPricelistNeed === null && props["record"] && props["permissions"]){
	// 	isPricelistNeed = props["record"]["company_id"] === props["permissions"]["company_id"]
	// }

	const isRoleForTerminalView = props.permissions && (
		props.permissions.role === UserRole.admin ||
		props.permissions.role === UserRole.app_manager ||
		props.permissions.role === UserRole.osfc_manager ||
		props.permissions.role === UserRole.osfc_employee ||
		props.permissions.role === UserRole.customer_manager ||
		props.permissions.role === UserRole.customer_employee ||
		props.permissions.role === UserRole.customer_and_transporter_manager ||
		props.permissions.role === UserRole.customer_and_transporter_employee
	);

	const isNot3rdPartyRole = UserRoleByCompanyType.customer_and_transporter_roles.indexOf(props.permissions.role) === -1
	
	return (
		<Tab label="General" {...props}>
			<FlexLayout>
				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					{isOSFCManager ? <ReferenceField source="company_id" reference="customer-companies" link={false}>
						<TextField source="full_name"/>
					</ReferenceField> : null}
					<TextField source="id"/>
					<TextField source="reference"/>
					<PriorityField source="priority"/>
					<ReferenceEditableField editable={isOSFCManager} source="status" reference="order-statuses"
											editField={<SelectInput optionText="name"/>}>
						<TextField source="short_name"/>
					</ReferenceEditableField>
					<ReferenceField source="cooler_type_id" reference="cooler-types" link={false}>
						<TextField source="name"/>
					</ReferenceField>
					{isRoleForTerminalView ? <ReferenceField source="terminal_id" reference="terminal" link={false}>
						<TextField label="Terminal" source="name"/>
					</ReferenceField> : null}
				</FlexRow>

				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					<SelectEnumField source="service" enumObj={OrderServiceNames} reverse />
					<YesNoField source="split_load"/>
					<NumberField source="loading_order"/>
					<NumberField source="trip_number"/>
					{/* <TextField source="load_no" /> */}
				</FlexRow>

				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					<LongTextField source="place_from"/>
					<DateFormatField source="eta_date"/>
					<TextField source="eta_time"/>
					<TextField source="eta_slot_time"/>
					<SelectTextField source="eta_truck" refSource="eta_truck_id" reference="trucks" />
					{isNot3rdPartyRole ? <SelectTextField source="eta_driver" refSource="eta_driver_id" reference="drivers" formatLabel={formatDriverLabel} /> 
					: <TransDriverFieldWrapper source="eta_driver" prefix="eta" addLabel />}
					<TextField source="eta_driver_phone"/>
					{ isNot3rdPartyRole ? <YesNoField source="eta_show_doc_for_trans" label="resources.orders.fields.eta_show_doc"/> : null}
					<TextEditableField editable={isOSFCManager} source="port_in"/>
				</FlexRow>

				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					<DateFormatField source="etd_date"/>
					<TextField source="etd_time"/>
					<TextField source="etd_slot_time"/>
					<SelectTextField source="etd_truck" refSource="etd_truck_id" reference="trucks" />
					{isNot3rdPartyRole ? <SelectTextField source="etd_driver" refSource="etd_driver_id" reference="drivers" formatLabel={formatDriverLabel} />
					: <TransDriverFieldWrapper source="etd_driver" prefix="etd" addLabel />}
					<TextField source="etd_driver_phone"/>
					{ isNot3rdPartyRole ? <YesNoField source="etd_show_doc_for_trans" label="resources.orders.fields.etd_show_doc"/> : null}
					<TextEditableField editable={isOSFCManager} source="port_out"/>
					<LongTextField source="place_to" />
				</FlexRow>

				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					<TextField source="commodity"/>
					<TextField source="pallets"/>
					<TextField source="boxes"/>
					<TextField source="kilos"/>
					<YesNoField source="is_doc_for_nfsa"/>
					<TextField source="label"/>
					<LabelSourceField source="label_source" />
					<ReferenceField sortable={false} source="area_id" reference="areas" link={false}>
						<TextField source="name" />
					</ReferenceField>
					<YesNoField source="is_area_blocked" />
				</FlexRow>

				<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
					<RichTextField source="notes" flex/>
					<RichTextField source="internal_notes" styleWarning flex/>
				</FlexRow>
				{!isCustomerAsTrans &&
					<FlexRow isNot3rdPartyRole={isNot3rdPartyRole}>
						<PricelistField label="resources.pricelist-articles.name" reference="order-pricelist" target="id" permissions={props.permissions}/>
					</FlexRow>
				}

			</FlexLayout>
		</Tab>
	);
};

export const OrderHistoryTab = props => {
	return (
		<Tab label="Order History" {...props}>
			<ReferenceField label="resources.order-editlog.created_by" source="created_by" reference="users" link={false}>
				<FunctionField render={record => `${record.first_name} ${record.last_name}`}/>
			</ReferenceField>
			<HistoryField label="resources.order-history.name" reference="order-history" target="original_id">
				<OrderHistoryGrid/>
			</HistoryField>
		</Tab>
	);
};

export const OrderPrcelistHistoryTab = props => {
	return (
		<Tab label="Order Pricelist History" {...props}>
			<ReferenceField label="resources.order-editlog.created_by" source="created_by" reference="users" link={false}>
				<FunctionField render={record => `${record.first_name} ${record.last_name}`}/>
			</ReferenceField>
			<HistoryField label="resources.order-pricelist-history.name" 
						  reference="order-pricelist" target="id" filter={{ include_permanently_deleted: true }}>
				<OrderPricelistHistoryGrid rowClick="expand" expand={<OrderPricelistHistoryField />} 
										   isRowExpandable={(record) => record.has_changes} />
			</HistoryField>
		</Tab>
	);
};

export const DocumentsTab = ({isOSFCManager, isCustomerAsTrans, ...props}) => {
	return (
		<Tab label="Documents" {...props}>
			<FlexLayout>
				<FlexRow>
					{isOSFCManager ?
						<ReferenceField source="company_id" reference="customer-companies" link={false}>
							<TextField source="full_name"/>
						</ReferenceField> : null}
					<TextField source="id"/>
					<TextField source="reference"/>
					<PriorityField source="priority"/>
					<ReferenceEditableField editable={isOSFCManager} source="status" reference="order-statuses"
											editField={<SelectInput optionText="name"/>}>
						<TextField source="short_name"/>
					</ReferenceEditableField>
				</FlexRow>
			</FlexLayout>
			<DocumentsFieldBlock variant="elevation" isCustomerAsTrans={isCustomerAsTrans} isOutputDocuments={false}/>
		</Tab>
	);
};

export const unloadingDisable = (record) => (
	// !(record.service === OrderService.intoPlukkStorage && record.eta_date && record.eta_time);
	!(record.service === OrderService.intoPlukkStorage && record.eta_date)
)
export const loadingDisable = (record) => (
	// !(record.service === OrderService.intoPlukkStorage && record.etd_date && record.etd_time);
	!(record.service === OrderService.intoPlukkStorage && record.etd_date)
)

export const OutDocumentsTab = ({isOSFCManager, isCustomerAsTrans, ...props}) => {
	const record = useRecordContext();

	const [isLoadingDoc, setIsLoadingDoc] = React.useState(false)
	const [isUnloadingDoc, setIsUnloadingDoc] = React.useState(false)

	const changeIsLoading = (isLoadingDocFlag) => {
		setIsLoadingDoc(isLoadingDocFlag)
	} 
	const changeIsUnloading = (isLoadingDocFlag) => {
		setIsUnloadingDoc(isLoadingDocFlag)
	}

	const unloading_disable = unloadingDisable(record);
	const loading_disable = loadingDisable(record);

	return (
		<Tab label="Out documents" {...props}>
			<Box display="flex" flex="1" justifyContent="center" alignItems="center">
				<GenerateDocumentsOutputButton type={OrderDocumentOutputType.unloading} disabled={unloading_disable} order={record} buttonText='resources.output-document.button.unloading.title' margin={3} isOutputDoc={isUnloadingDoc}/>
				<GenerateDocumentsOutputButton type={OrderDocumentOutputType.loading} disabled={loading_disable} order={record} buttonText='resources.output-document.button.loading.title' isOutputDoc={isLoadingDoc}/>				
			</Box>
			<DocumentsFieldBlock variant="elevation" isCustomerAsTrans={isCustomerAsTrans} isOutputDocuments={true} changeIsLoading={changeIsLoading} changeIsUnloading={changeIsUnloading}/>
		</Tab>
	);
};

export const ChatTab = ({isOSFCManager, ...props}) => {
	return (
		<Tab label="Chat" {...props}>
			<ChatContainer/>
		</Tab>
	);
};
