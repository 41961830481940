import React from 'react';

import { Show, TextField, SimpleShowLayout } from 'react-admin';

import { ActionsWithBackButton } from '../../../components/actionbars/ActionsWithBackButton';
import { SelectEnumField } from '../../../components/fields/SelectEnumField';
import { DateTimeField } from '../../../components/fields/DateFormatField';

import { EventsType } from '../../../entities/EventsType';
import { EventStatus } from '../../../entities/EventStatus';


export const EventsShow = (props) => (
    <Show 
        actions={<ActionsWithBackButton hasEdit hasDelete />} 
        {...props}
    >
        <SimpleShowLayout>
            <SelectEnumField 
                source='type' 
                enumName='events_type' 
                enumObj={EventsType} 
            />

            <SelectEnumField 
                source='status' 
                enumName='events_status' 
                enumObj={EventStatus} 
            />

            <DateTimeField source='start_datetime' addLabel withLocalTimeZone />
            <DateTimeField source='end_datetime' addLabel withLocalTimeZone />

            <TextField source='title' />
            <TextField source='description' />
        </SimpleShowLayout>
    </Show>
);
