import React from "react";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { useTranslate } from "react-admin";
import {
	buttonGroupStyle,
	StyledToggleButton,
} from "../../../components/filters/FilterToggleInput";
import { TERMINAL_ALL_VALUE } from "../../../components/filters/ToggleTerminalsFilter";

import {
	useIsDraggableContext,
	useDraggableIDsContext,
	useDragsHistoryContext,
	reorderIds,
} from "../../../components/DraggableDatagrid";

const StyledToggleButtonGroup = buttonGroupStyle(ToggleButtonGroup);

const mapIdsSortOrders = (ids) => {
	return ids.map((id, i) => ({ id: id, sort_order: i + 1 }));
};

export const CancelSaveButtons = ({ filterValues, onDragsSave }) => {
	const translate = useTranslate();
	const { draggableIDs, setDraggableIDs } = useDraggableIDsContext();
	const { historyChanges, setHistoryChanges } = useDragsHistoryContext();
	const isCancelSaveActive = !!historyChanges.length;

	React.useEffect(() => {
		setHistoryChanges([]);
	}, [filterValues?.terminal_id]);

	const handleOnCancel = () => {
		if (isCancelSaveActive) {
			const lastChange = historyChanges[historyChanges.length - 1];
			const previousIdsOrder = reorderIds(
				draggableIDs,
				lastChange.destinationID,
				lastChange.sourceID
			);
			setDraggableIDs(previousIdsOrder);
			setHistoryChanges(historyChanges.slice(0, -1));
		}
	};
	const handleOnSave = () => {
		if (isCancelSaveActive) {
			setHistoryChanges([]);
			onDragsSave({ items: mapIdsSortOrders(draggableIDs) });
		}
	};
	return (
		<StyledToggleButtonGroup value={isCancelSaveActive}>
			<StyledToggleButton
				value={true}
				onClick={handleOnCancel}
				disabled={!isCancelSaveActive}
			>
				{translate("ra.action.undo")}
			</StyledToggleButton>
			<StyledToggleButton value={true} onClick={handleOnSave} disabled={!isCancelSaveActive}>
				{translate("ra.action.save")}
			</StyledToggleButton>
		</StyledToggleButtonGroup>
	);
};

export const DragAndDropActionsButtons = ({ filterValues, onDragsSave }) => {
	const translate = useTranslate();
	const { isDraggable, setIsDraggable } = useIsDraggableContext();

	const isDNDAllowed = !(filterValues?.terminal_id[0] === TERMINAL_ALL_VALUE);
	React.useEffect(() => {
		if (!isDNDAllowed) setIsDraggable(isDNDAllowed);
	}, [isDNDAllowed]);

	if (isDNDAllowed)
		return (
			<React.Fragment>
				{isDraggable && (
					<CancelSaveButtons filterValues={filterValues} onDragsSave={onDragsSave} />
				)}
				<StyledToggleButtonGroup
					value={isDraggable}
					exclusive
					onChange={(event, value) => {
						setIsDraggable(value);
					}}
				>
					<StyledToggleButton value={true}>
						{translate("osfc.buttons.drag_and_drop")}
					</StyledToggleButton>
				</StyledToggleButtonGroup>
			</React.Fragment>
		);
	return null;
};
