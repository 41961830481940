import React from 'react';
import { useNotify } from 'react-admin';

import { uploadFile } from '../../../api/uploadFile';
import { httpClient } from '../../../api/httpClient';
import { useNotifyError } from '../../../utils/notifiers/useNotifyError';


export const useEntityFileUpload = (handleAdded, entityId, resource) => {
	const notify = useNotify(false);
	const notifyError = useNotifyError();	
	const [ uploaded, setUploaded ] = React.useState(undefined);
	const [ uploading, setUploading ] = React.useState(false);

	const resetUpload = React.useCallback(() => {
		setUploaded(undefined);
	}, []);

	const handleUploadFile = React.useCallback(async (file) => {
		setUploading(true);

		try {
	        httpClient(`${resource}/${entityId}/documents`, {
	            method: 'POST',
	            body: null,
	        }).then(async ({ json }) => {
				const uploadedFile = await uploadFile(
					`files/${resource}/${entityId}/documents/${json.id}`, 
					file
				);
				setUploaded({
					...json,
					title: uploadedFile.original.filename,
					src: uploadedFile.file.filename,
					preview_src: uploadedFile.file.src,
					thumbnail: uploadedFile.thumbnail.filename,
					preview_thumbnail: uploadedFile.thumbnail.src,
				});	
				setUploading(false);
				handleAdded();
			}).catch(error => {
				setUploading(false);
				notifyError(error);
			});			
		} catch(error) {			
			if (error.body && error.body.message) {
				notify(error.body.message, "error");
			} else if (error.status === 413) {
				notify("File is too large", "error");
			} else {
				notifyError(error);
			}
			console.dir(error);
		}
	}, [resource, entityId, handleAdded, notifyError, notify]);

	return [ uploading, handleUploadFile, uploaded, resetUpload ];
};
