import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { syntaxDocumentation } from '../../../contexts/EventBannerProvider';
import { defaultReactJsonOptions } from '../../../components/fields/JsonTextField';
import { JsonField } from 'react-admin-json-view';

const SyntaxDialog = ({ open, setOpen }) => (
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="body"
        fullWidth
    >
        <DialogContent>
            <JsonField
                record={{"syntaxDoc": JSON.stringify(syntaxDocumentation)}}
                source="syntaxDoc"
                jsonString 
                reactJsonOptions={defaultReactJsonOptions}
            />
        </DialogContent>
    </Dialog>
);

export default SyntaxDialog;
