import React, { useEffect, useState } from "react";

import { Draggable } from "@hello-pangea/dnd";
import { Loading } from "react-admin";
import { useDraggableIDsContext } from "./DraggableIDsContext";

const DraggableDatagridRow = ({ Component, ...props }) => {
	const { draggableIDs } = useDraggableIDsContext();
	const [recordIndex, setRecordIndex] = useState(null);
	const { record } = props;

	useEffect(() => {
		if (draggableIDs) {
			const rcdIndex = draggableIDs.indexOf(record.id);
			setRecordIndex(rcdIndex);
		}
		// else {
		// 	console.log("Don't forget use DraggableIDsProvider")
		// }
	}, [draggableIDs, record.id]);

	if (recordIndex === null || !draggableIDs) {
		return <tr><td><Loading loadingSecondary="" /></td></tr>;
	}
	
	return (
		<Draggable draggableId={`${record.id}`} key={record.id} index={recordIndex}>
			{(provided, snapshot) => (
				<Component
					{...props}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				/>
			)}
		</Draggable>
	);
};

export { DraggableDatagridRow };
